import { Switch as ArkSwitch } from '@ark-ui/react';
import cn from 'classnames';

import styles from './Switch.module.scss';

export type SwitchProps = {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  className: string;
  label: string;
};

const Switch = (props: SwitchProps): JSX.Element => {
  const { checked, onCheckedChange, className, label } = props || {};
  return (
    <ArkSwitch.Root
      checked={checked}
      onCheckedChange={(e) => {
        onCheckedChange(e.checked);
      }}
      className={cn(styles.root, className)}
    >
      <ArkSwitch.Label className={styles.label}>{label}</ArkSwitch.Label>
      <ArkSwitch.Control className={styles.control}>
        <ArkSwitch.Thumb className={styles.thumb} />
      </ArkSwitch.Control>
      <ArkSwitch.HiddenInput />
    </ArkSwitch.Root>
  );
};

export default Switch;
