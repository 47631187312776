import { useMsal } from '@azure/msal-react';
import useSWR from 'swr';

import { useMsalCustom } from './useMsalCustom';

export const useDataFetch = <TData = unknown>(endpoint: string | null) => {
  const { instance } = useMsal();
  const { msalFetch } = useMsalCustom();
  const fetcher = async (endpoint: string) => {
    const response = await msalFetch(endpoint);
    if (response.ok) {
      const data = await response.json();
      return data as TData;
    } else {
      const forbiddenStatusCode = 403;
      if (response.status === forbiddenStatusCode) {
        instance.loginRedirect();
        return;
      }
      const data = await response.json();
      throw new Error(`Failed to fetch data: ${endpoint}. ${data}`);
    }
  };

  const { data, isLoading, error } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
    dedupingInterval: 60_000,
    keepPreviousData: true,
  });

  return {
    data,
    isLoading,
    error,
  };
};
