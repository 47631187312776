import cn from 'classnames';

import ArrowLeft from 'assets/icons/arrow-left.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';

import styles from './Pagination.module.scss';

export type PaginationProps = {
  total: number;
  pageSize: number;
  currentPage: number;
  className?: string;
  setCurrentPage: (pageNumber: number) => void;
};

const Pagination = ({
  total,
  pageSize,
  currentPage,
  className,
  setCurrentPage,
}: PaginationProps): JSX.Element => {
  const totalPages = Math.ceil(total / pageSize);

  const getNumbers = () => {
    const numbers = Array.from({ length: totalPages }).map((_, index) => ({
      label: `${(index + 1).toString().padStart(2, '0')}`,
      pageNumber: index + 1,
    }));
    const numbersNeeded = 5;
    const numbersNeededBeforeCurrentPage = numbersNeeded - Math.min(2, totalPages - currentPage);
    const startIndex = Math.max(0, currentPage - numbersNeededBeforeCurrentPage);
    const endIndex = startIndex + numbersNeeded;
    return numbers.slice(startIndex, endIndex);
  };

  const numbers = getNumbers();

  return (
    <div className={cn(styles.main, className)}>
      <ul className={styles.controls}>
        <li className={styles.item}>
          <button
            className={cn(styles['arrow'], styles['arrow-prev'])}
            aria-label="Previous Page"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
            data-gtm-acm={`previous-button-page-${currentPage - 1}`}
          >
            <ArrowLeft className={styles.icon} />
          </button>
        </li>
        {numbers.map((number) => (
          <li className={styles.item} key={number.pageNumber}>
            <button
              className={cn(styles.number, {
                [styles['number--active']]: number.pageNumber === currentPage,
              })}
              aria-label={`Page ${number.pageNumber}`}
              onClick={() => setCurrentPage(number.pageNumber)}
              aria-current={number.pageNumber === currentPage ? 'true' : 'false'}
              data-gtm-acm={`set-page-to-page-${number.pageNumber}`}
            >
              {number.label}
            </button>
          </li>
        ))}
        <li className={styles.item}>
          <button
            className={cn(styles['arrow'], styles['arrow-next'])}
            aria-label="Next Page"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
            data-gtm-acm={`next-button-page-${currentPage - 1}`}
          >
            <ArrowRight className={styles.icon} />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
