import { Popover } from '@ark-ui/react';
import cn from 'classnames';
import { forwardRef } from 'react';

import { gtmDataLayerPush } from 'src/hooks/useGoogleTagManager';
import { useMsalCustom } from 'src/hooks/useMsalCustom';

import BaseCta, { BaseCtaProps } from 'components/BaseHelpers/BaseCta';

import Text from '../../../../BaseHelpers/Text';

import styles from './MegaMenuLink.module.scss';

export type MegaMenuLinkProps = BaseCtaProps & {
  number?: number;
  subheadline?: string;
  className?: string;
  label: string;
  variant?: 'primary' | 'secondary';
};

const MegaMenuLink = forwardRef<HTMLAnchorElement, MegaMenuLinkProps>(
  function MegaMenuLinkComponent(props: MegaMenuLinkProps, ref): JSX.Element {
    const {
      label,
      number,
      subheadline = '',
      className,
      variant = 'primary',
      ...otherProps
    } = props || {};
    const paddedNumber = number && number !== -1 ? number.toString().padStart(2, '0') : '';
    const { accountInfo } = useMsalCustom();

    if (!label) {
      return <></>;
    }
    return (
      <Popover.Context>
        {(context) => {
          return (
            <BaseCta
              className={cn(styles.main, className, styles[variant])}
              {...otherProps}
              ref={ref}
              data-gtm-selector="header-link"
              onClick={() => {
                context.setOpen(false);
                try {
                  gtmDataLayerPush({
                    event: 'NavLabelClick',
                    Click: `Top Nav - ${label}`,
                    pageUrl: location.pathname,
                    pageTitle: document.title,
                    userId: accountInfo?.localAccountId,
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              <div className={styles.labels}>
                <Text className={styles.number} field={paddedNumber} />
                <div className={styles.text}>
                  <Text className={styles.label} field={label} />
                  <Text tag="p" className={styles.subheadline} field={subheadline} />
                </div>
              </div>
            </BaseCta>
          );
        }}
      </Popover.Context>
    );
  }
);

export default MegaMenuLink;
