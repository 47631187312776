import React from 'react';

import { hasItems } from 'src/utils/hasItems';

import { RouteHandlerReturn } from 'src/types/model';

import Placeholders from 'components/BaseHelpers/Placeholders';

import styles from './DefaultTemplate.module.scss';

export type DefaultTemplateProps = {
  routeData: RouteHandlerReturn;
};

const DefaultTemplate = ({ routeData }: DefaultTemplateProps): JSX.Element => {
  const content = routeData.props?.route?.placeholders?.content || [];
  const header = routeData.props?.route?.placeholders?.header || [];
  const footer = routeData.props?.route?.placeholders?.footer || [];

  return (
    <div className={styles.main}>
      {hasItems(header) && <Placeholders placeholders={header} />}
      {hasItems(content) && (
        <main id="main" className={styles.placeholders}>
          <Placeholders placeholders={content} />
        </main>
      )}
      {hasItems(footer) && <Placeholders placeholders={footer} />}
    </div>
  );
};
export default DefaultTemplate;
