import { Popover, Portal } from '@ark-ui/react';
import { useRef, useState } from 'react';

import { useMsalCustom } from 'src/hooks/useMsalCustom';
import { useOnResize } from 'src/hooks/useOnResize';
import { useOnScroll } from 'src/hooks/useOnScroll';

import Text from 'components/BaseHelpers/Text';

import SignOut from '../../../../../assets/icons/sign-out.svg';
import User from '../../../../../assets/icons/user.svg';

import styles from './UserAccount.module.scss';

export type UserAccountProps = {
  signOutLabel: string;
};

export const UserAccountProfile = (props: UserAccountProps): JSX.Element => {
  const { setOnResizeFn } = useOnResize();
  const { accountInfo, logout } = useMsalCustom();
  const ref = useRef<HTMLDivElement>(null);

  setOnResizeFn(() => {
    const userAccountHeight = ref.current?.offsetHeight;
    document.documentElement.style.setProperty('--UserAccount__height', `${userAccountHeight}px`);
  });

  const { signOutLabel } = props || {};

  if (!accountInfo) {
    return <></>;
  }

  return (
    <div ref={ref} className={styles.account}>
      <Text className={styles.name} field={accountInfo?.name || ''} />
      <button aria-label="sign out user" className={styles.button} onClick={logout}>
        <Text field={signOutLabel} /> <SignOut className={styles['sign-out']} />
      </button>
    </div>
  );
};

const UserAccount = (props: UserAccountProps): JSX.Element => {
  const { signOutLabel } = props || {};
  const [profileOpen, setProfileOpen] = useState<boolean>(false);
  const { setOnResizeFn } = useOnResize();
  const { accountInfo } = useMsalCustom();

  const { setOnScrollFn } = useOnScroll();
  setOnScrollFn(() => {
    setProfileOpen(false);
  });

  setOnResizeFn(() => {
    setProfileOpen(false);
  });

  if (!accountInfo) {
    return <></>;
  }

  return (
    <div className={styles.main}>
      <Popover.Root
        onOpenChange={(open) => {
          setProfileOpen(open.open);
        }}
        portalled
        open={profileOpen}
        closeOnInteractOutside={true}
      >
        <Popover.Trigger className={styles['toggle']} aria-label="Open User Profile Menu">
          <User />
        </Popover.Trigger>
        <Portal>
          <Popover.Content asChild>
            <div className={styles['account-profile']}>
              <UserAccountProfile signOutLabel={signOutLabel} />
            </div>
          </Popover.Content>
        </Portal>
      </Popover.Root>
    </div>
  );
};

export default UserAccount;
