import cn from 'classnames';

import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { ComponentProps } from '../../../types/component-props';
import { CoreRichTextData } from '../../../types/components';

import styles from './CoreRichText.module.scss';

export type CoreRichTextProps = ComponentProps & CoreRichTextData;

const CoreRichText = (props: CoreRichTextProps): JSX.Element => {
  const { moduleHeader, description } = props.fields || {};
  return (
    <div className={cn(styles.main, 'container-6', 'spacer')}>
      <Text tag="h2" field={moduleHeader} className={styles.header} />
      <RichText className={styles['rich-text']} field={description} />
    </div>
  );
};

export default CoreRichText;
