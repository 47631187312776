import { Collapsible } from '@ark-ui/react';
import cn from 'classnames';
import { useState } from 'react';

import { useGlobalSettings } from 'src/hooks/useGlobalSettings';

import { hasItems } from 'src/utils/hasItems';

import RichText from 'components/BaseHelpers/RichText';
import Switch from 'components/BaseHelpers/Switch';
import Text from 'components/BaseHelpers/Text';

import CaretDown from '../../../assets/icons/caret-down.svg';
import { ComponentProps } from '../../../types/component-props';
import { CoreAccordionData } from '../../../types/components';

import styles from './CoreAccordion.module.scss';

export type CoreAccordionProps = ComponentProps & CoreAccordionData;

const CoreAccordion = (props: CoreAccordionProps): JSX.Element => {
  const { moduleHeader, accordions } = props.fields || {};

  const [openStates, setOpenStates] = useState(accordions.map(() => false));

  const { getSettingsLabel } = useGlobalSettings();

  const toggleIndividual = (index: number) => {
    const newStates = [...openStates];
    newStates[index] = !newStates[index];
    setOpenStates(newStates);
  };

  const toggleAll = (newState: boolean) => {
    setOpenStates(openStates.map(() => newState));
  };

  const allOpen = openStates.every((isOpen) => isOpen);

  if (!hasItems(accordions)) {
    return <></>;
  }

  return (
    <div className={cn(styles.main, 'container-6', 'spacer', 'borders')}>
      {(accordions.length > 1 || moduleHeader) && (
        <div className={styles.top}>
          <Text field={moduleHeader} className={styles.header} />
          {accordions.length > 1 && (
            <Switch
              label={
                allOpen ? getSettingsLabel('collapseAllLabel') : getSettingsLabel('expandAllLabel')
              }
              className={styles.switch}
              checked={allOpen}
              onCheckedChange={toggleAll}
            />
          )}
        </div>
      )}
      <ul className={styles.accordions}>
        {accordions.map((accordion, index) => {
          const { header, description } = accordion || {};
          return (
            header &&
            description && (
              <li key={`accordion-${index}`} className={styles.accordion}>
                <Collapsible.Root open={openStates[index]}>
                  <Collapsible.Trigger
                    onClick={() => toggleIndividual(index)}
                    className={styles.trigger}
                  >
                    <Text tag="p" className={styles['trigger-label']} field={header} />
                    <CaretDown className={styles.caret} />
                  </Collapsible.Trigger>
                  <Collapsible.Content className={styles.content}>
                    <RichText className={styles.text} field={description} />
                  </Collapsible.Content>
                </Collapsible.Root>
              </li>
            )
          );
        })}
      </ul>
    </div>
  );
};

export default CoreAccordion;
