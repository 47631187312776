import Text from 'components/BaseHelpers/Text';

import { CoreAllCourseMaterialSearchData } from '../../../types/components';
import FiltersPanel from './components/FiltersPanel';
import ResultsPanel from './components/ResultsPanel';
import { useACMSearch } from './useACMSearch';

import styles from './CoreAllCourseMaterialSearch.module.scss';

export type CoreAllCourseMaterialSearchProps = CoreAllCourseMaterialSearchData;

const CoreAllCourseMaterialSearch = ({ fields }: CoreAllCourseMaterialSearchProps): JSX.Element => {
  const { formRef, onSubmit, data, isLoading, searchKey, hasSearchFilters, clearFilters } =
    useACMSearch();
  const { eyebrow, header } = fields;
  return (
    <section className={styles.main}>
      <div className={styles.form}>
        <FiltersPanel
          className={styles['filters']}
          ref={formRef}
          allFacultySelectOptionLabel={fields.allFacultySelectOptionLabel}
          allSessionsToggleLabel={fields.allSessionsToggleLabel}
          sessionInvalidMessage={fields.sessionInvalidMessage}
          applyFiltersLabel={fields.applyFiltersLabel}
          facultyLabel={fields.facultyLabel}
          filters={fields.filters}
          keywordLabel={fields.keywordLabel}
          keywordPlaceholder={fields.keywordPlaceholder}
          sessionLabel={fields.sessionLabel}
          trackLabel={fields.trackLabel}
          onSubmit={onSubmit}
        />
      </div>
      <div className={styles.content}>
        <Text className={styles.eyebrow} tag="p" field={eyebrow} />
        <Text className={styles.header} tag="h1" field={header} />
        <ResultsPanel
          materialsTabLabel={fields.materialsTabLabel}
          videosTabLabel={fields.videosTabLabel}
          resultsCountTemplate={fields.resultsCountTemplate}
          noResultsMessage={fields.noResultsMessage}
          results={data}
          resultsPerPage={fields.resultsPerPage}
          clearAllFiltersLabel={fields.clearAllFiltersLabel}
          hasSearchFilters={hasSearchFilters}
          isLoading={isLoading}
          searchKey={searchKey}
          clearFilters={clearFilters}
        />
      </div>
    </section>
  );
};

export default CoreAllCourseMaterialSearch;
