import { useEffect, useState } from 'react';

type UseOnMountCallback = () => void;

/**
 * Run a function once on mount
 * @param onMountCallback
 * @returns
 */
export const useOnMount = (onMountCallback?: UseOnMountCallback) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    if (onMountCallback) {
      onMountCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isMounted,
  };
};
