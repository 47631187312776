import { useGTMMaterialClick } from 'src/hooks/useGoogleTagManager';

import Link from 'components/BaseHelpers/Link';
import Text from 'components/BaseHelpers/Text';

import { ComponentProps } from '../../../types/component-props';
import { CoreMaterialCardData } from '../../../types/components';

import styles from './CoreMaterialCard.module.scss';

export type CoreMaterialCardProps = ComponentProps & CoreMaterialCardData;

const CoreMaterialCard = (props: CoreMaterialCardProps): JSX.Element => {
  const { clickHandler } = useGTMMaterialClick();
  const { id, fields } = props || {};
  const { name, track, sessionTags, link, format } = fields || {};

  return (
    <Link
      {...link}
      onClick={() => {
        clickHandler({ materialType: 'Material', id, name, format, linkUrl: link?.href });
      }}
      className={styles.main}
    >
      <Text className={styles.track} field={track} />
      <Text className={styles.name} field={name} />
      <ul className={styles['session-tags']}>
        {sessionTags.map((tag, index) => {
          return (
            <li key={index}>
              <Text className={styles['session-tag']} field={tag.tag} />
            </li>
          );
        })}
      </ul>
    </Link>
  );
};

export default CoreMaterialCard;
