import cn from 'classnames';
import { createElement, forwardRef } from 'react';

import styles from './RichText.module.scss';

export type RichTextProps = {
  field: string;
  styled?: boolean;
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
};

const RichText = forwardRef<HTMLDivElement, RichTextProps>(function BaseHelpersRichText(
  props: RichTextProps,
  ref
): JSX.Element {
  const { styled = true, className, field, tag = 'div' } = props;

  const customClassName = className;

  if (field) {
    return createElement(tag, {
      className: cn(styled && styles.main, customClassName),
      ref: ref,
      dangerouslySetInnerHTML: {
        __html: field,
      },
    });
  }
  return <></>;
});

export default RichText;
