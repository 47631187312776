import cn from 'classnames';
import { forwardRef } from 'react';

import BaseCta, { BaseCtaProps } from '../BaseCta';

import styles from './TextCta.module.scss';

export type TextCtaProps = BaseCtaProps & {
  noIcon?: boolean;
  label?: string;
};

const TextCta = forwardRef<HTMLAnchorElement | HTMLButtonElement, TextCtaProps>(
  function TextCtaComponent(props: TextCtaProps, ref): JSX.Element {
    const { label } = props || {};
    if (!label) {
      return <></>;
    }
    if (!props.tag || props.tag === 'a') {
      const { target, noIcon, ...otherAnchorProps } = props;
      return (
        <BaseCta
          className={cn(
            styles.main,
            { external: !noIcon && target === '_blank' },
            { 'no-icon': noIcon }
          )}
          target={target}
          {...otherAnchorProps}
          ref={ref}
        >
          {label}
        </BaseCta>
      );
    }
    if (props.tag === 'button' || props.tag === 'span') {
      const { noIcon, ...otherButtonProps } = props;
      return (
        <BaseCta ref={ref} {...otherButtonProps} className={cn(styles.main, { 'no-icon': noIcon })}>
          {label}
        </BaseCta>
      );
    }

    return <></>;
  }
);

export default TextCta;
