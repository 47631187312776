import { Tabs } from '@ark-ui/react';
import cn from 'classnames';
import { useState } from 'react';

import { gtmDataLayerPush } from 'src/hooks/useGoogleTagManager';
import { useMsalCustom } from 'src/hooks/useMsalCustom';

import {
  AllCourseMaterialSearchResults,
  CoreAllCourseMaterialSearchData,
} from 'src/types/components';

import Grid from 'assets/icons/grid.svg';
import List from 'assets/icons/list.svg';

import ResultsTab from '../ResultsTab';

import styles from './ResultsPanel.module.scss';

export type ResultsPanelProps = Pick<
  CoreAllCourseMaterialSearchData['fields'],
  | 'materialsTabLabel'
  | 'videosTabLabel'
  | 'resultsCountTemplate'
  | 'resultsPerPage'
  | 'clearAllFiltersLabel'
  | 'noResultsMessage'
> & {
  results: AllCourseMaterialSearchResults | undefined;
  isLoading: boolean;
  searchKey: string;
  hasSearchFilters: boolean;
  clearFilters: () => void;
};

export type ResultsDisplayFormat = 'grid' | 'list';
const ResultsPanel = ({
  materialsTabLabel,
  videosTabLabel,
  resultsCountTemplate,
  noResultsMessage,
  resultsPerPage,
  results,
  searchKey,
  clearAllFiltersLabel,
  clearFilters,
  hasSearchFilters,
}: ResultsPanelProps): JSX.Element => {
  const [resultsFormat, setResultsFormat] = useState<ResultsDisplayFormat>('grid');
  const [tabFormat, setTabFormat] = useState<string | null>('videos');
  const { accountInfo } = useMsalCustom();

  return (
    <div className={styles.main}>
      <Tabs.Root
        defaultValue={'videos'}
        value={tabFormat}
        onValueChange={(e) => setTabFormat(e.value)}
        activationMode="manual"
      >
        <div className={styles.top}>
          <Tabs.List className={styles.triggers}>
            <Tabs.Trigger
              className={styles.trigger}
              value="videos"
              onClick={() => {
                try {
                  gtmDataLayerPush({
                    event: 'AllCoursesVideosTab',
                    pageUrl: location.pathname,
                    pageTitle: document.title,
                    userId: accountInfo?.localAccountId,
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              <div className={styles['trigger-label']}>
                {videosTabLabel} ({results?.video.total})
              </div>
            </Tabs.Trigger>
            <Tabs.Trigger
              className={styles.trigger}
              value="materials"
              onClick={() => {
                try {
                  gtmDataLayerPush({
                    event: 'AllCoursesMaterialsTab',
                    pageUrl: location.pathname,
                    pageTitle: document.title,
                    userId: accountInfo?.localAccountId,
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              <div className={styles['trigger-label']}>
                {materialsTabLabel} ({results?.material.total})
              </div>
            </Tabs.Trigger>
            <Tabs.Indicator />
          </Tabs.List>
          <div
            className={styles.buttons}
            ata-gtm-acm={JSON.stringify({ resultsFormat: resultsFormat })}
          >
            <button
              className={cn(styles['button'], styles['button--grid'], {
                [styles['button--active']]: resultsFormat === 'grid',
              })}
              aria-label="Display results in grid format"
              onClick={() => setResultsFormat('grid')}
              data-gtm-acm="acm-results-grid"
            >
              <Grid className={styles.icon} />
            </button>
            <button
              className={cn(styles['button'], styles['button--list'], {
                [styles['button--active']]: resultsFormat === 'list',
              })}
              aria-label="Display results in list format"
              onClick={() => setResultsFormat('list')}
              data-gtm-acm="acm-results-list"
            >
              <List className={styles.icon} />
            </button>
          </div>
        </div>
        <Tabs.Context>
          {(context) => {
            return (
              <>
                <div
                  {...context.getContentProps({ value: 'videos' })}
                  tabIndex={undefined}
                  data-gtm-acm="acm-videos-tab"
                >
                  <ResultsTab
                    resultsCountTemplate={resultsCountTemplate}
                    resultsFormat={resultsFormat}
                    noResultsMessage={noResultsMessage}
                    results={results?.video}
                    resultsPerPage={resultsPerPage}
                    key={searchKey}
                    clearAllFiltersLabel={clearAllFiltersLabel}
                    clearFilters={clearFilters}
                    hasSearchFilters={hasSearchFilters}
                  />
                </div>
                <div
                  {...context.getContentProps({ value: 'materials' })}
                  tabIndex={undefined}
                  data-gtm-acm="acm-materials-tab"
                >
                  <ResultsTab
                    resultsCountTemplate={resultsCountTemplate}
                    resultsFormat={resultsFormat}
                    noResultsMessage={noResultsMessage}
                    results={results?.material}
                    resultsPerPage={resultsPerPage}
                    clearAllFiltersLabel={clearAllFiltersLabel}
                    hasSearchFilters={hasSearchFilters}
                    clearFilters={clearFilters}
                    key={searchKey}
                  />
                </div>
              </>
            );
          }}
        </Tabs.Context>
      </Tabs.Root>
    </div>
  );
};

export default ResultsPanel;
