import { Dialog, Portal } from '@ark-ui/react';
import { useState } from 'react';

import { useGTMMaterialClick } from 'src/hooks/useGoogleTagManager';

import Close from '../../../../../assets/icons/close.svg';
import { CoreVideoCardProps, VideoCard, VideoContent } from '../../CoreVideoCard';

import styles from './VideoModal.module.scss';

export type VideoModalProps = CoreVideoCardProps;

const VideoModal = (props: VideoModalProps): JSX.Element => {
  const { id, fields } = props || {};
  const { embedCode, format, name } = fields || {};
  const [modalOpen, setModalOpen] = useState(false);
  const { clickHandler } = useGTMMaterialClick();

  return (
    <Dialog.Root
      closeOnInteractOutside
      onExitComplete={() => {
        setModalOpen(false);
      }}
      onOpenChange={(open) => {
        if (open.open) {
          setModalOpen(true);
          clickHandler({ materialType: 'Video', id, name, format });
        }
      }}
    >
      <Dialog.Context>
        {(context) => {
          const triggerProps = {
            ...context.getTriggerProps(),
            // Remove 'aria-expanded' per zoho IC2-I117 https://bugtracker.zoho.com/portal/onenorth#buginfo/404500000009959003/404500000009991184
            'aria-expanded': undefined,
          };
          return (
            <button
              className={styles.main}
              onClick={() => context.setOpen(!context.open)}
              {...triggerProps}
            >
              <VideoCard {...props} />
            </button>
          );
        }}
      </Dialog.Context>
      <Portal>
        <Dialog.Backdrop className={styles.backdrop} />
        <Dialog.Positioner className={styles.positioner}>
          <Dialog.Content className={styles.content}>
            <Dialog.CloseTrigger
              aria-label="Close Modal"
              className={styles.close}
            ></Dialog.CloseTrigger>
            {modalOpen && (
              <div className={styles.modal}>
                <div dangerouslySetInnerHTML={{ __html: embedCode }} />
                <VideoContent {...props}></VideoContent>
              </div>
            )}
            <Dialog.CloseTrigger aria-label="Close Modal" className={styles.close}>
              <Close />
            </Dialog.CloseTrigger>
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  );
};

export default VideoModal;
