import type { AppProps } from 'next/app';
import MsalAuthentication from 'src/MsalAuthentication';

import { EnvVariablesContextProvider } from 'src/hooks/useEnvVariables';

import { RouteHandlerReturn } from 'src/types/model';

import ErrorBoundary from 'components/BaseHelpers/ErrorBoundary';

import '../styles/global/global.scss';

function App({ Component, pageProps }: AppProps<RouteHandlerReturn>): JSX.Element {
  return (
    <MsalAuthentication
      azb2cEnabled={pageProps.env?.azb2cEnabled || false}
      azb2cApiDomain={pageProps.env?.azb2cApiDomain || ''}
      azb2cAuthorityDomain={pageProps.env?.azb2cAuthorityDomain || ''}
      azb2cClientId={pageProps.env?.azb2cClientId || ''}
      azb2cLoginRedirectPath={pageProps.env?.azb2cLoginRedirectPath || ''}
    >
      <ErrorBoundary>
        <EnvVariablesContextProvider env={pageProps.env}>
          <Component {...pageProps} />
        </EnvVariablesContextProvider>
      </ErrorBoundary>
    </MsalAuthentication>
  );
}

export default App;
