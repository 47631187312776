import { PropsWithChildren, createContext, useContext } from 'react';

import { EnvVariables } from 'src/types/model';

export interface EnvVariablesContextValue {
  env: EnvVariables | undefined;
  // Add more global context values and their associated types here
}

export const EnvVariablesContext = createContext<EnvVariablesContextValue | undefined>(undefined);

export const EnvVariablesContextProvider = ({
  env,
  children,
}: PropsWithChildren<EnvVariablesContextValue>) => {
  return <EnvVariablesContext.Provider value={{ env }}>{children}</EnvVariablesContext.Provider>;
};

export const useEnvVariables = () => {
  const context = useContext(EnvVariablesContext);
  if (context === undefined) {
    throw new Error('useEnvVariables must be used within a EnvVariablesContextProvider');
  }
  return context;
};
