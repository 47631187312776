import cn from 'classnames';

import { useGlobalSettings } from 'src/hooks/useGlobalSettings';

import { CorePeopleCardData } from 'src/types/components';

import BoxCta from 'components/BaseHelpers/BoxCta';
import Image from 'components/BaseHelpers/Image';

import styles from './PeopleGridItem.module.scss';

export const PeopleGridItem = (props: CorePeopleCardData & { isFaculty: boolean }) => {
  const { name, title, office, linkedInUrl, bioLink, image } = props.fields;
  const hasLinks = Boolean(linkedInUrl || bioLink.href);
  const { getSettingsLabel } = useGlobalSettings();

  return (
    <div className={cn(styles['people-grid-item'], { [styles['faculty']]: props.isFaculty })}>
      <div className={styles['wrapper']}>
        <div className={styles['image-container']}>
          {image && <Image {...image} fill={true} className={styles['image']} />}
        </div>
        <div className={styles['content-container']}>
          <h3 className={styles['name']}>
            <span>{name.first}</span>
            <span>{name.last}</span>
          </h3>
          {props.isFaculty && (
            <>
              <span className={styles['title']}>{title}</span>
              <span className={styles['office']}>{office}</span>
            </>
          )}
        </div>
      </div>

      {hasLinks && (
        <div className={styles['button-container']}>
          {linkedInUrl && (
            <BoxCta
              aria-label={`${name.first} ${name.last}'s ${getSettingsLabel('linkedinLabel')}`}
              className={styles['link']}
              tag="a"
              label={getSettingsLabel('linkedinLabel')}
              variant="secondary"
              target="_blank"
              href={linkedInUrl}
            >
              {getSettingsLabel('linkedinLabel')}
            </BoxCta>
          )}
          {bioLink.href && (
            <BoxCta
              aria-label={`${name.first} ${name.last}'s ${getSettingsLabel('bioLabel')}`}
              className={styles['link']}
              tag="a"
              label={getSettingsLabel('bioLabel')}
              href={bioLink.href}
              target={bioLink.target}
            >
              {getSettingsLabel('bioLabel')}
            </BoxCta>
          )}
        </div>
      )}
    </div>
  );
};
