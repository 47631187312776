import {
  ButtonHTMLAttributes,
  MouseEventHandler,
  ReactNode,
  forwardRef,
  ForwardedRef,
  HTMLAttributes,
} from 'react';

import Link, { LinkProps } from '../Link';
import Text from '../Text';
import { useBaseCta } from './useBaseCta';

type AnchorProps = {
  tag: 'a';
  ref?: ForwardedRef<HTMLAnchorElement>;
  children?: ReactNode;
} & LinkProps;

type ButtonProps = {
  tag: 'button';
  ref?: ForwardedRef<HTMLButtonElement>;
  onClick: MouseEventHandler;
} & (
  | {
      label: string | undefined;
      children?: ReactNode;
    }
  | {
      label?: string | undefined;
      children?: ReactNode;
    }
) &
  ButtonHTMLAttributes<HTMLButtonElement>;

type SpanProps = {
  tag: 'span';
  label?: string;
  children?: ReactNode;
} & HTMLAttributes<HTMLSpanElement>;

export type BaseCtaProps = AnchorProps | ButtonProps | SpanProps;

const BaseCta = forwardRef<HTMLAnchorElement | HTMLButtonElement, BaseCtaProps>(
  function BaseCtaComponent(props: BaseCtaProps, ref): JSX.Element {
    const { shouldRenderCta } = useBaseCta();

    if (!shouldRenderCta(props)) {
      return <></>;
    }

    if (props.tag === 'a') {
      const { children, className, tag, ...otherProps } = props;

      return (
        <Link className={className} {...otherProps} ref={ref as ForwardedRef<HTMLAnchorElement>}>
          {children}
        </Link>
      );
    }

    if (props.tag === 'span') {
      const { children, className, label, ...otherProps } = props;
      if (!children && !label) return <></>;
      return (
        <span className={className} {...otherProps}>
          {children || label}
        </span>
      );
    }

    const { children, className, label, onClick, tag, ...otherProps } = props;
    return (
      <button
        className={className}
        onClick={onClick}
        {...otherProps}
        ref={ref as ForwardedRef<HTMLButtonElement>}
      >
        {children || (typeof label === 'string' ? <Text field={label} /> : '')}
      </button>
    );
  }
);

export default BaseCta;
