import { useEffect, useRef } from 'react';

type OnScrollFn = () => void;
export const useOnScroll = (fn?: OnScrollFn) => {
  const onScrollFn = useRef<OnScrollFn | undefined>(fn);

  useEffect(() => {
    const handler = () => requestAnimationFrame(() => onScrollFn.current && onScrollFn.current());
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  return {
    setOnScrollFn: (fn: OnScrollFn) => (onScrollFn.current = fn),
    clearOnScrollFn: () => (onScrollFn.current = undefined),
  };
};
