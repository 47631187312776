import { Browser, Console, Loggly, createLogger } from './package';

export { updateError } from './package';

export const logger = createLogger({
  transports: [
    new Console(),
    new Browser({ endpoint: process.env.LOGGER_API_ENDPOINT || '/api/logger' }),
    new Loggly({
      token: process.env.LOGGLY_TOKEN ?? '',
      subdomain: process.env.LOGGLY_SUBDOMAIN || 'logs-01',
      tags: process.env.LOGGLY_TAGS || 'sidley,jss,frontend',
      level: 'error',
    }),
  ],
});
