import dynamic from 'next/dynamic';

// Render this component client side since it needs to read `sessionStorage` which is
// only available in the browser
const CoreCodeOfConductModal = dynamic(() => import('./CoreCodeOfConductModal'), {
  ssr: false,
});

export { type CoreCodeOfConductModalProps } from './CoreCodeOfConductModal';
export default CoreCodeOfConductModal;
