import { Checkbox } from '@ark-ui/react';

import { SelectOptionData } from 'src/types/components';

import styles from './TrackOptions.module.scss';

export type TrackOptionsProps = {
  label: string;
  name: string;
  options: SelectOptionData[];
};

const TrackOptions = ({ label, name, options }: TrackOptionsProps): JSX.Element => {
  if (options.length === 0) {
    return <></>;
  }

  return (
    <div className={styles.main}>
      <div>
        <div id="track-label" className={styles['filter-label']}>
          {label}
        </div>
      </div>
      <div role="group" className={styles.group} aria-labelledby="track-label">
        {options.map((option) => (
          <Checkbox.Root
            className={styles.checkbox}
            value={option.value}
            name={name}
            key={option.value}
          >
            <Checkbox.HiddenInput data-gtm-acm="acm-track-filter" />
            <Checkbox.Label className={styles['label']}>{option.label}</Checkbox.Label>
          </Checkbox.Root>
        ))}
      </div>
    </div>
  );
};

export default TrackOptions;
