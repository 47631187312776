import cn from 'classnames';

import { hasItems } from 'src/utils/hasItems';

import Image from 'components/BaseHelpers/Image';
import Text from 'components/BaseHelpers/Text';

import Play from '../../../assets/icons/play.svg';
import { ComponentProps } from '../../../types/component-props';
import { CoreVideoCardData } from '../../../types/components';
import VideoModal from './components/VideoModal';

import styles from './CoreVideoCard.module.scss';

export type CoreVideoCardProps = ComponentProps & CoreVideoCardData;

export const VideoContent = (props: CoreVideoCardProps): JSX.Element => {
  const { track, name, sessionTags } = props.fields || {};
  return (
    <div className={styles.content}>
      <Text className={styles.track} field={track} />
      <Text className={styles.name} field={name} />
      <ul className={styles['session-tags']}>
        {sessionTags?.map((tag, index) => {
          return (
            <li key={index}>
              <Text className={styles['session-tag']} field={tag.tag} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const VideoCard = (props: CoreVideoCardData): JSX.Element => {
  const { fields } = props || {};
  const { format, image } = fields || {};
  return (
    <div className={cn(styles.main, styles[format])}>
      <div className={styles.image}>
        {image && <Image fill {...image} />}
        <div className={styles['play-button']}>
          <Text field="Play" />
          <Play className={styles['play-icon']} />
        </div>
      </div>
      <div className={styles['card-content']}>
        <VideoContent {...props} />
      </div>
    </div>
  );
};

const CoreVideoCard = (props: CoreVideoCardProps): JSX.Element => {
  if (!props.fields.name || !hasItems(props.fields.sessionTags)) {
    return <></>;
  }

  return <VideoModal {...props} />;
};

export default CoreVideoCard;
