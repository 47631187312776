import cn from 'classnames';

import Text from 'components/BaseHelpers/Text';
import VideoEmbed from 'components/BaseHelpers/VideoEmbed';

import { ComponentProps } from '../../../types/component-props';
import { CoreSessionRecordingData } from '../../../types/components';

import styles from './CoreSessionRecording.module.scss';

export type CoreSessionRecordingProps = ComponentProps & CoreSessionRecordingData;

const CoreSessionRecording = (props: CoreSessionRecordingProps): JSX.Element => {
  const { eyebrow, header, embedCode } = props.fields || {};
  if (!embedCode) {
    return <></>;
  }
  return (
    <div className={cn(styles.main, 'container-8', 'spacer', 'borders')}>
      {(eyebrow || header) && (
        <div className={styles.text}>
          <Text className={styles.eyebrow} field={eyebrow} />
          <Text className={styles.header} tag="h2" field={header} />
        </div>
      )}

      <VideoEmbed embedCode={embedCode} />
    </div>
  );
};

export default CoreSessionRecording;
