import { Logger, LoggerOptions } from './Logger';
import { Json } from './formatters/Json';
import Console from './transports/Console';

export const createLogger = function (options?: Partial<LoggerOptions>): Logger {
  return new Logger({
    level: options?.level,
    silent: options?.silent ?? false,
    transports: options?.transports || [new Console()],
    formatter: options?.formatter || new Json(),
    metaData: options?.metaData || {},
  });
};
