import cn from 'classnames';
import { forwardRef } from 'react';

import ArrowDiagonalRight from '../../../assets/icons/arrow-diagonal-right.svg';
import ArrowRight from '../../../assets/icons/arrow-right.svg';
import BaseCta, { BaseCtaProps } from '../BaseCta';

import styles from './BoxCta.module.scss';

export type BoxCtaProps = BaseCtaProps & {
  className?: string;
  label?: string;
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
};

const BoxCta = forwardRef<HTMLAnchorElement | HTMLButtonElement, BoxCtaProps>(
  function BoxCtaComponent(props: BoxCtaProps, ref): JSX.Element {
    const { variant = 'primary', className, label, tag } = props;

    // Adding this line because even though BaseCTA will check if a label is present, it will also render if children are present and we always have children due to the svgs rendering

    if (!label) {
      return <></>;
    }

    if (tag === 'a') {
      const { target, ...otherAnchorProps } = props;
      return (
        <BaseCta
          target={target}
          {...otherAnchorProps}
          className={cn(styles.main, styles[variant], className)}
          ref={ref}
        >
          {label}
          {target === '_blank' ? (
            <ArrowDiagonalRight className={styles.icon} />
          ) : (
            <ArrowRight className={styles.icon} />
          )}
        </BaseCta>
      );
    }
    if (tag === 'button' || tag === 'span') {
      return (
        <BaseCta ref={ref} {...props} className={cn(styles.main, styles[variant], className)}>
          {label}
          <ArrowRight className={styles.icon} />
        </BaseCta>
      );
    }
    return <></>;
  }
);

export default BoxCta;
