import { Popover } from '@ark-ui/react';
import cn from 'classnames';

import Text from 'components/BaseHelpers/Text';
import MegaMenuLink from 'components/Core/CoreGlobalNavigationHeader/components/MegaMenuLink';

import { PrimaryNavigationItem } from '../../../../../types/components';

import styles from './MegaMenu.module.scss';

export type MegaMenuProps = {
  primaryNavigationItem: PrimaryNavigationItem;
};

const MegaMenu = (props: MegaMenuProps): JSX.Element => {
  const { primaryNavigationItem } = props || {};
  if (primaryNavigationItem.format === 'sessions') {
    const half = Math.ceil(primaryNavigationItem.sessions.length / 2);
    const leftColumnItems = primaryNavigationItem.sessions.slice(0, half);
    const rightColumnItems = primaryNavigationItem.sessions.slice(half);
    return (
      <Popover.Content className={cn(styles.main, styles[primaryNavigationItem.format])}>
        <div className={styles.columns}>
          <div className={cn(styles.column, styles[`column--0`])}>
            <MegaMenuLink tag="a" {...primaryNavigationItem.link} />
            {leftColumnItems?.map((session, index) => {
              const { sessionNumber, sessionDate, link } = session || {};
              return (
                <MegaMenuLink
                  tag="a"
                  key={index}
                  {...link}
                  number={sessionNumber}
                  subheadline={sessionDate}
                />
              );
            })}
          </div>
          <div className={cn(styles.column, styles[`column--1`])}>
            {rightColumnItems?.map((session, index) => {
              const { sessionNumber, sessionDate, link } = session || {};
              return (
                <MegaMenuLink
                  tag="a"
                  key={index}
                  {...link}
                  number={sessionNumber}
                  subheadline={sessionDate}
                />
              );
            })}
          </div>
        </div>
      </Popover.Content>
    );
  }
  if (
    primaryNavigationItem.format === 'internal_links' ||
    primaryNavigationItem.format === 'internal_external_links'
  ) {
    return (
      <Popover.Content className={cn(styles.main, styles[primaryNavigationItem.format])}>
        <div className={styles.columns}>
          {primaryNavigationItem?.columns?.map((column, columnIndex) => {
            const { label, links } = column || {};

            return (
              <div
                key={columnIndex}
                className={cn(styles.column, styles[`column--${columnIndex}`])}
              >
                <Text className={styles['links-label']} field={label} />
                {links.map((link, linkIndex) => (
                  <MegaMenuLink
                    tag="a"
                    variant={
                      primaryNavigationItem.format === 'internal_external_links' &&
                      columnIndex === 1
                        ? 'secondary'
                        : 'primary'
                    }
                    key={linkIndex}
                    {...link}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </Popover.Content>
    );
  }
  return <></>;
};

export default MegaMenu;
