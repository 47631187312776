import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { SelectOptionData } from 'src/types/components';

import CaretDown from 'assets/icons/dropdown-caret-down.svg';

import styles from './SelectDropdown.module.scss';

export type SelectDropdownProps = {
  label: string;
  name: string;
  options: SelectOptionData[];
  className?: string;
  clear?: boolean;
};

const SelectDropdown = ({
  label,
  name,
  options,
  className,
  clear,
}: SelectDropdownProps): JSX.Element => {
  const ref = useRef<HTMLLabelElement>(null);
  const initialValue = options[0].value;
  const [selectedValue, setSelectedValue] = useState(initialValue);
  const selectedLabel = options.find((option) => option.value === selectedValue)?.label || '';

  useEffect(() => {
    if (clear) {
      setSelectedValue(initialValue);
    }
  }, [clear]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const handleReset = () => setSelectedValue(initialValue);
    const formEl = ref.current.closest<HTMLFormElement>('form');
    if (!formEl) {
      return;
    }
    formEl.addEventListener('reset', handleReset);
    return () => formEl.removeEventListener('reset', handleReset);
  }, []);

  return (
    <label className={cn(styles.main, className)} ref={ref}>
      <span className={styles['filter-label']}>{label}</span>
      <div className={styles.container}>
        <p className={styles.selected}>{selectedLabel}</p>
        <CaretDown className={styles.icon} />
        <select
          className={styles.select}
          name={name}
          value={selectedValue}
          onChange={(event) => setSelectedValue(event.currentTarget.value)}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
};

export default SelectDropdown;
