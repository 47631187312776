import { GoogleTagManager } from '@next/third-parties/google';
import Head from 'next/head';
import Scripts from 'src/Scripts';

import { useEnvVariables } from './hooks/useEnvVariables';
import { useGTMPageView } from './hooks/useGoogleTagManager';
import { RouteContextProvider } from './hooks/useRouteContext';
import DefaultTemplate from './templates/DefaultTemplate';
import { RouteHandlerReturn } from './types/model';

const Layout = ({ routeData }: { routeData: RouteHandlerReturn }): JSX.Element => {
  const { env } = useEnvVariables();
  const gtmId = env?.googleTagManagerId;
  const pageTitle = routeData.props?.pageTitle || 'Cooley IPO Institute';

  useGTMPageView(pageTitle);
  return (
    <>
      <Scripts />
      <Head>
        <title>{pageTitle}</title>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <link rel="icon" href="/favicon.svg" />
      </Head>
      {gtmId && <GoogleTagManager gtmId={gtmId} />}
      {routeData && (
        <RouteContextProvider routeData={routeData.props}>
          <DefaultTemplate routeData={routeData} />
        </RouteContextProvider>
      )}
    </>
  );
};

export default Layout;
