import styles from './VideoEmbed.module.scss';

export type VideoEmbedProps = {
  embedCode: string;
};

const VideoEmbed = (props: VideoEmbedProps): JSX.Element => {
  const { embedCode } = props;

  if (!embedCode) {
    return <></>;
  }
  const embedCodeNoAutoplay = embedCode.replace(/&autoplay=1/g, '');
  return <div className={styles.main} dangerouslySetInnerHTML={{ __html: embedCodeNoAutoplay }} />;
};

export default VideoEmbed;
