import cn from 'classnames';

import { useGlobalSettings } from 'src/hooks/useGlobalSettings';

import { hasItems } from 'src/utils/hasItems';

import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { ComponentProps } from '../../../types/component-props';
import { CoreSessionMaterialsData } from '../../../types/components';
import CoreMaterialCard from '../CoreMaterialCard';
import CoreVideoCard from '../CoreVideoCard';

import styles from './CoreSessionMaterials.module.scss';

export type CoreSessionMaterialsProps = ComponentProps & CoreSessionMaterialsData;

const CoreSessionMaterials = (props: CoreSessionMaterialsProps): JSX.Element => {
  const { header, description, videoCards, materialCards } = props.fields || [];
  const { getSettingsLabel } = useGlobalSettings();

  if (!header || (!hasItems(videoCards) && !hasItems(materialCards))) {
    return <></>;
  }
  return (
    <div className={cn(styles.main, 'container-8', 'spacer')}>
      <div className={styles.text}>
        <Text tag="h2" className={styles.header} field={header} />
        <RichText field={description} />
      </div>

      {hasItems(videoCards) && (
        <div className={styles.materials}>
          <Text tag="h3" className={styles.label} field={getSettingsLabel('videosSectionLabel')} />
          <div className={styles.grid}>
            {videoCards.map((videoCard, index) => {
              return (
                <div key={index}>
                  <CoreVideoCard {...videoCard} />
                </div>
              );
            })}
          </div>
        </div>
      )}
      {hasItems(materialCards) && (
        <div className={styles.materials}>
          <Text
            tag="h3"
            className={styles.label}
            field={getSettingsLabel('materialsSectionLabel')}
          />
          <div className={styles.grid}>
            {materialCards.map((materialCard, index) => {
              return (
                <div key={index}>
                  <CoreMaterialCard {...materialCard} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CoreSessionMaterials;
