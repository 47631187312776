import { createElement, forwardRef } from 'react';

export type TextProps = {
  field: string;
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
};

const HEADINGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const Text = forwardRef<HTMLDivElement, TextProps>(function BaseHelpersText(
  props: TextProps,
  ref
): JSX.Element {
  const { field, tag = 'span', className } = props || {};
  if (field) {
    return createElement(tag, {
      className,
      ref: ref,
      tabIndex: HEADINGS.includes(tag) ? -1 : undefined,
      dangerouslySetInnerHTML: {
        __html: field,
      },
    });
  }
  return <></>;
});

export default Text;
