import cn from 'classnames';

import BoxCta from 'components/BaseHelpers/BoxCta';
import Text from 'components/BaseHelpers/Text';

import AlertIcon from '../../../../../assets/icons/alert.svg';
import SuccessIcon from '../../../../../assets/icons/success.svg';
import { SubmitFormSubmissionMessageData } from '../../../../../types/components';

import styles from './SubmitFormSubmissionMessage.module.scss';

export type SubmitFormSubmissionMessageProps = SubmitFormSubmissionMessageData & {
  messageType: 'success' | 'error';
  clickHandler: () => void;
};

const SubmitFormSubmissionMessage = (props: SubmitFormSubmissionMessageProps): JSX.Element => {
  const { label, message, ctaLabel, clickHandler, messageType } = props;
  return (
    <div className={cn(styles.main)}>
      <div
        role="alert"
        aria-live="assertive"
        className={'visually-hidden'}
        dangerouslySetInnerHTML={{ __html: `${label} ${message}` }}
      ></div>
      <div className={styles.message}>
        <div className={styles.icon}>
          {messageType === 'success' ? (
            <SuccessIcon />
          ) : messageType === 'error' ? (
            <AlertIcon />
          ) : (
            <></>
          )}
        </div>
        <Text className={styles.label} field={label} />
        <Text field={message} />
      </div>
      <BoxCta aria-label={'Go back to form'} tag="button" onClick={clickHandler} label={ctaLabel} />
    </div>
  );
};

export default SubmitFormSubmissionMessage;
