import cn from 'classnames';

import BoxCta from 'components/BaseHelpers/BoxCta';
import Image from 'components/BaseHelpers/Image';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';
import TextCta from 'components/BaseHelpers/TextCta';

import { ComponentProps } from '../../../types/component-props';
import { CoreCallToActionData } from '../../../types/components';

import styles from './CoreCallToAction.module.scss';

export type CoreCallToActionProps = ComponentProps & CoreCallToActionData;

const CoreCallToAction = (props: CoreCallToActionProps): JSX.Element => {
  const { headline, description, image, theme, format, primaryCta, secondaryCta } = props.fields;

  if (!headline) {
    return <></>;
  }
  return (
    <div className={cn(styles.main, styles[format], theme, 'container-8', 'spacer', 'borders')}>
      <div className={styles.content}>
        <Text tag="h2" className={styles.headline} field={headline} />
        <RichText className={styles.description} field={description} />
        <div className={styles.ctas}>
          {format !== 'no_image' ? (
            <>
              {primaryCta && <TextCta tag="a" {...primaryCta} />}
              {secondaryCta && <TextCta tag="a" {...secondaryCta} />}
            </>
          ) : (
            <>
              {primaryCta && <BoxCta className={styles.cta} tag="a" {...primaryCta} />}
              {secondaryCta && <TextCta tag="a" {...secondaryCta} />}
            </>
          )}
        </div>
      </div>

      {format !== 'no_image' && image && (
        <div className={styles.image}>
          <Image {...image} fill={true} />
        </div>
      )}
    </div>
  );
};

export default CoreCallToAction;
