import { Configuration, PopupRequest, LogLevel, BrowserCacheLocation } from '@azure/msal-browser';

import { useEnvVariables } from './hooks/useEnvVariables';
import { EnvVariables } from './types/model';

type OptionalEnvironmentVars = Partial<EnvVariables>;

const b2cPolicies = (env?: OptionalEnvironmentVars) => {
  const authorityDomain = env?.azb2cAuthorityDomain || process.env.AZB2C_AUTHORITY_DOMAIN || '';
  const apiDomain = env?.azb2cApiDomain || process.env.AZB2C_API_DOMAIN || '';

  return {
    names: {
      signIn: 'B2C_1_sign_in',
      passwordReset: 'B2C_1_password_reset',
    },
    authorities: {
      signIn: {
        authority: `https://${authorityDomain}/${apiDomain}/B2C_1_sign_in`,
      },
      passwordReset: {
        authority: `https://${authorityDomain}/${apiDomain}/B2C_1_password_reset`,
      },
    },
    authorityDomain,
  };
};

// config object to be passed to MSAL on creation
export const msalConfig = (env?: OptionalEnvironmentVars): Configuration => {
  const policies = b2cPolicies(env);
  const clientId = env?.azb2cClientId || process.env.AZB2C_CLIENT_ID || '';
  const loginRedirectPath =
    env?.azb2cLoginRedirectPath || process.env.AZB2C_LOGIN_REDIRECT_PATH || '';

  return {
    auth: {
      clientId,
      authority: policies.authorities.signIn.authority,
      knownAuthorities: [policies.authorityDomain],
      redirectUri: loginRedirectPath,
      postLogoutRedirectUri: loginRedirectPath,
    },
    system: {
      allowNativeBroker: false, // disables WAM Broker
      loggerOptions: {
        logLevel: LogLevel.Error,
        /* loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        }, */
        piiLoggingEnabled: false,
      },
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
      secureCookies: false,
    },
  };
};

export const apiConfig = (env?: OptionalEnvironmentVars) => {
  const apiDomain = env?.azb2cApiDomain || process.env.AZB2C_API_DOMAIN || '';
  return {
    apiDomain: `https://${apiDomain}`,
    protectedRoutes: {
      apiRead: {
        scopeName: 'Api.Read',
      },
    },
  };
};

// add here scopes for id token to be used as MS Identity Platform endpoints
export const loginRequest: PopupRequest = {
  scopes: ['https://graph.microsoft.com/offline_access', 'https://graph.microsoft.com/openid'],
};

// add here the endpoints for MS Graph API services you would like to use
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const useAuthConfig = () => {
  const { env } = useEnvVariables();

  return {
    apiConfig: apiConfig(env),
    msalConfig: msalConfig(env),
    loginRequest,
  };
};
