import cn from 'classnames';
import { useRef, useState } from 'react';

import { hasItems } from 'src/utils/hasItems';

import { AllCourseMaterialSearchResults } from 'src/types/components';

import ClearIcon from 'assets/icons/clear.svg';

import RichText from 'components/BaseHelpers/RichText';
import CoreMaterialCard from 'components/Core/CoreMaterialCard';
import CoreVideoCard from 'components/Core/CoreVideoCard';

import Pagination from '../Pagination';
import { ResultsDisplayFormat } from '../ResultsPanel/ResultsPanel';

import styles from './ResultsTab.module.scss';

export type ResultsTabProps = {
  resultsCountTemplate: string;
  resultsFormat: ResultsDisplayFormat;
  results: AllCourseMaterialSearchResults[keyof AllCourseMaterialSearchResults] | undefined;
  noResultsMessage: string;
  resultsPerPage: number;
  clearAllFiltersLabel: string;
  hasSearchFilters: boolean;
  clearFilters: () => void;
};

const ResultsTab = ({
  results,
  resultsCountTemplate,
  resultsFormat,
  noResultsMessage,
  resultsPerPage = 10,
  clearAllFiltersLabel,
  clearFilters,
  hasSearchFilters,
}: ResultsTabProps): JSX.Element => {
  const resultsRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const firstItemIndex = (currentPage - 1) * resultsPerPage;
  const lastItemIndex = currentPage * resultsPerPage;

  const getResultsCountLabel = () => {
    const startIndex = (currentPage - 1) * resultsPerPage + 1;
    const endIndex = currentPage * resultsPerPage;

    let range = '';
    const { total = 0 } = results || {};

    if (total === 0 || total === 1) {
      range = total.toString();
    } else if (endIndex >= total) {
      range = `${startIndex}-${total}`;
    } else {
      range = `${startIndex}-${endIndex}`;
    }

    const rangeToken = '{CURRENT_RANGE}';
    const totalToken = '{TOTAL}';
    return resultsCountTemplate
      .replaceAll(rangeToken, range)
      .replaceAll(totalToken, total.toString());
  };

  const isVideo = Boolean(
    results?.results.some(({ componentName }) => componentName === 'CoreVideoCard')
  );

  return (
    <div className={styles.main} ref={resultsRef}>
      <div className={styles['top-row']}>
        <h2 className={styles['results-count']} aria-live="polite" aria-atomic="true">
          {getResultsCountLabel()}
        </h2>
        {hasSearchFilters && (
          <button className={styles['clear-button']} onClick={clearFilters}>
            {clearAllFiltersLabel} <ClearIcon className={styles['clear-icon']} />
          </button>
        )}
      </div>
      {hasItems(results?.results) && (
        <div
          className={cn(
            styles.items,
            {
              [styles['items--grid']]: resultsFormat === 'grid',
              [styles['items--list']]: resultsFormat === 'list',
              [styles['items--videos']]: isVideo,
              [styles['items--material']]: !isVideo,
            },
            resultsFormat
          )}
        >
          {results?.results.slice(firstItemIndex, lastItemIndex).map((result) => (
            <div className={styles.item} key={result.id}>
              {result.componentName === 'CoreVideoCard' ? (
                <CoreVideoCard {...result} fields={{ ...result.fields, format: resultsFormat }} />
              ) : (
                <CoreMaterialCard
                  {...result}
                  fields={{ ...result.fields, format: resultsFormat }}
                />
              )}
            </div>
          ))}
        </div>
      )}
      {results && results.total > resultsPerPage && (
        <Pagination
          className={styles.pagination}
          currentPage={currentPage}
          pageSize={resultsPerPage}
          total={results?.total || 0}
          setCurrentPage={(pageNumber: number) => {
            setCurrentPage(pageNumber);
            // workaround for ios not scrolling
            setTimeout(() => {
              if (resultsRef.current) {
                const headerHeight =
                  document.getElementById('CoreGlobalNavigationHeader')?.clientHeight || 0;

                const buffer = 20;
                const offset = headerHeight + buffer;
                const top =
                  resultsRef.current.getBoundingClientRect().top + window.pageYOffset - offset;
                window.scrollTo({
                  top,
                  left: 0,
                  behavior: 'smooth',
                });
              }
            }, 10);

            // wait until the scrolling is done before focusing
            setTimeout(() => {
              resultsRef.current
                ?.querySelector<HTMLAnchorElement | HTMLButtonElement>('a,button')
                ?.focus();
            }, 1000);
          }}
        />
      )}
      {results?.total === 0 && (
        <RichText className={styles['no-results-message']} field={noResultsMessage} />
      )}
    </div>
  );
};

export default ResultsTab;
