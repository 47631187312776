import cn from 'classnames';

import { hasItems } from 'src/utils/hasItems';

import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { ComponentProps } from '../../../types/component-props';
import { CorePeopleGridData } from '../../../types/components';
import { PeopleGridItem } from './PeopleGridItem';

import styles from './CorePeopleGrid.module.scss';

export type CorePeopleGridProps = ComponentProps & CorePeopleGridData;

const CorePeopleGrid = (props: CorePeopleGridProps): JSX.Element => {
  const { header, description, cards, variant } = props.fields;

  if (!hasItems(cards)) return <></>;

  return (
    <div className={cn(styles.main, 'container-8', 'borders')}>
      <Text tag="h2" className={styles.header} field={header} />
      <RichText className={styles.description} field={description} />
      <ul
        className={cn(styles['card-list'], {
          [styles['faculty']]: variant === 'faculty',
          [styles['participant']]: variant === 'participant',
        })}
      >
        {cards.map((card, index) => {
          return (
            <li
              className={styles['card-list-item']}
              key={`${index}-${card.fields.name.first}-${card.fields.name.last}`}
            >
              <PeopleGridItem {...card} isFaculty={variant === 'faculty'} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CorePeopleGrid;
