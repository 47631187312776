import cn from 'classnames';
import { FormEvent, forwardRef, useEffect, useRef, useState } from 'react';

import { useOnResize } from 'src/hooks/useOnResize';

import { CoreAllCourseMaterialSearchData } from 'src/types/components';

import AlertIcon from 'assets/icons/alert.svg';

import BoxCta from 'components/BaseHelpers/BoxCta';
import SelectDropdown from 'components/BaseHelpers/SelectDropdown';
import Text from 'components/BaseHelpers/Text';

import CheckboxGroup from '../CheckboxGroup';
import ModalWhenMobile from '../ModalWhenMobile';
import TextInput from '../TextInput';
import TrackOptions from '../TrackOptions';

import styles from './FiltersPanel.module.scss';

export type FiltersPanelProps = Pick<
  CoreAllCourseMaterialSearchData['fields'],
  | 'allFacultySelectOptionLabel'
  | 'allSessionsToggleLabel'
  | 'sessionInvalidMessage'
  | 'applyFiltersLabel'
  | 'facultyLabel'
  | 'filters'
  | 'keywordLabel'
  | 'keywordPlaceholder'
  | 'sessionLabel'
  | 'trackLabel'
> & {
  onSubmit: (event: FormEvent) => void;
  className?: string;
};

const FiltersPanel = forwardRef<HTMLFormElement, FiltersPanelProps>(
  function FiltersPanelComponent(props, ref): JSX.Element {
    const {
      applyFiltersLabel,
      keywordLabel,
      keywordPlaceholder,
      sessionLabel,
      allSessionsToggleLabel,
      sessionInvalidMessage,
      filters,
      facultyLabel,
      trackLabel,
      onSubmit,
      className,
    } = props;

    const { setOnResizeFn } = useOnResize();
    const ctaContainerRef = useRef<HTMLDivElement>(null);
    const [isCheckboxInvalid, setIsCheckboxInvalid] = useState(false);
    const { containerRef } = useScrollToCheckboxWhenTabbing();
    const setContainerHeight = () => {
      const ctaContainer = ctaContainerRef.current?.offsetHeight;
      document.documentElement.style.setProperty('--CtaContainerRef__height', `${ctaContainer}px`);
    };

    setOnResizeFn(() => {
      setContainerHeight();
    });

    useEffect(() => {
      setContainerHeight();
    }, [isCheckboxInvalid]);

    return (
      <ModalWhenMobile>
        <form
          className={cn(styles.main, className)}
          ref={ref}
          onSubmit={onSubmit}
          data-gtm-acm-form
        >
          <div className={styles['container']} ref={containerRef}>
            <div className={styles.wrapper}>
              <TextInput
                label={keywordLabel}
                name="keyword"
                placeholder={keywordPlaceholder}
                data-gtm-acm="acm-keyword-filter"
              />
            </div>
            <div className={styles.line} />
            <div className={styles.wrapper}>
              <SelectDropdown
                label={facultyLabel}
                name="faculty"
                options={filters.faculty}
                data-gtm-acm={`acm-dropdown-faculty-filter`}
              />
            </div>
            <div className={styles.line} />
            <div className={styles.wrapper}>
              <CheckboxGroup
                label={sessionLabel}
                name="sessions"
                options={filters.sessions}
                toggleLabel={allSessionsToggleLabel}
                setIsInvalid={setIsCheckboxInvalid}
              />
            </div>
            <div className={styles.line} />
            <div className={styles.wrapper}>
              <TrackOptions label={trackLabel} name="track" options={filters.track} />
            </div>
          </div>
          <div className={styles['sticky-track']}>
            <div className={styles['cta-container']} ref={ctaContainerRef}>
              {isCheckboxInvalid && (
                <div className={styles['error-message']} role="alert">
                  <AlertIcon className={styles['alert-icon']} />
                  <Text className={styles['error-message-label']} field={sessionInvalidMessage} />
                </div>
              )}
              <BoxCta
                tag="button"
                onClick={() => {}}
                className={cn(styles.cta, 'light')}
                type="submit"
                label={applyFiltersLabel}
                disabled={isCheckboxInvalid}
                data-gtm-acm="acm-submit-button"
              />
            </div>
          </div>
        </form>
      </ModalWhenMobile>
    );
  }
);

export default FiltersPanel;

const useScrollToCheckboxWhenTabbing = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const handler = (event: KeyboardEvent) => {
      if (event.key === 'Tab' && containerRef.current) {
        const focusedElement = containerRef.current.querySelector(
          'input[type="checkbox"]:focus + [data-part="label"]'
        );
        if (focusedElement) {
          const containerRect = containerRef.current.getBoundingClientRect();
          const containerBottom = containerRect.height + containerRef.current.scrollTop;
          const elementRect = focusedElement.getBoundingClientRect();
          const isVisible = containerBottom >= elementRect.bottom;

          if (!isVisible) {
            const top = elementRect.top - containerRef.current.scrollTop - containerRect.top;
            containerRef.current.scrollTo({
              top,
              left: 0,
            });
          }
        }
      }
    };
    containerRef.current.addEventListener('keydown', handler);
    return () => containerRef.current?.removeEventListener('keydown', handler);
  }, []);

  return {
    containerRef,
  };
};
