import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import { useMsalCustom } from './useMsalCustom';

// https://github.com/vercel/next.js/discussions/20784#discussioncomment-4101864
type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
};
declare const window: WindowWithDataLayer;

export const gtmDataLayerPush = (data: Record<string, unknown>) => {
  if (window && window.dataLayer) {
    window.dataLayer.push(data);
  }
};

export const useGTMPageView = (pageTitle: string) => {
  const router = useRouter();
  const triggeredOnPageLoad = useRef(false);
  const { accountInfo } = useMsalCustom();

  useEffect(() => {
    const isInitialPageLoad = !triggeredOnPageLoad.current;

    const trackPageView = () => {
      try {
        // Trigger a custom event called SPAPageView with the page data.
        // GTM will be configured to listen to this event.
        const data = {
          event: 'SPAPageView',
          pagePath: `/${router.locale}${router.asPath}`,
          pageTitle: pageTitle,
          userId: accountInfo?.localAccountId,
        };
        gtmDataLayerPush(data);

        if (!isInitialPageLoad) {
          // Trigger the gtm.load event on subsequent navigations which resets most
          // GTM triggers (like scroll depth)
          gtmDataLayerPush({ event: 'gtm.load' });
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (isInitialPageLoad && window && window.dataLayer) {
      trackPageView();
      triggeredOnPageLoad.current = true;
    }

    const handler = () => setTimeout(trackPageView, 100);
    router.events.on('routeChangeComplete', handler);
    return () => {
      router.events.off('routeChangeComplete', handler);
    };
  }, [router]);
};

interface FormDataObject {
  [key: string]: FormDataEntryValue;
}

interface MaterialInfo {
  materialType: string;
  id: number;
  name: string;
  format: 'grid' | 'list';
  linkUrl?: string;
}

export const useGTMMaterialClick = () => {
  const { accountInfo } = useMsalCustom();

  const clickHandler = (materialInfo: MaterialInfo) => {
    const formElement = document.querySelector<HTMLFormElement>('[data-gtm-acm-form]');
    const formData = formElement ? new FormData(formElement) : null;

    const formDataObject: FormDataObject = {};

    if (formData) {
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
    }
    try {
      gtmDataLayerPush({
        event: `${materialInfo.materialType}Click`,
        userId: accountInfo?.localAccountId,
        url: window?.location?.href,
        ...materialInfo,
        ...formDataObject,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return {
    clickHandler,
  };
};
