import cn from 'classnames';
import NextImage, { ImageProps as NextImageProps, ImageLoader, ImageLoaderProps } from 'next/image';

import styles from './Image.module.scss';

export type ImageProps = NextImageProps & {
  className?: string;
};

const cloudflareLoader: ImageLoader = (props: ImageLoaderProps): string => {
  const { src, width, quality = 90 } = props;
  const params = [`width=${width}`, `quality=${quality}`, 'format=auto'];
  return `/cdn-cgi/image/${params.join(',')}/${src}`;
};

const Image = (props: ImageProps): JSX.Element => {
  return (
    <NextImage
      sizes="100vw"
      loader={cloudflareLoader}
      unoptimized={process.env.NEXT_PUBLIC_RESIZE_IMAGES !== 'true'}
      {...props}
      className={cn(props.className, styles.main)}
    />
  );
};
export default Image;
