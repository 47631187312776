import {
  PublicClientApplication,
  EventType,
  AuthenticationResult,
  InteractionType,
} from '@azure/msal-browser';
//msal imports
import {
  MsalProvider,
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
} from '@azure/msal-react';
import { PropsWithChildren, useRef } from 'react';

import LoadingScreen from 'components/BaseHelpers/LoadingScreen';

import { msalConfig, loginRequest } from './authConfig';
import ErrorPage from './pages/_error';
import { EnvVariables } from './types/model';

const ProtectedError: React.FC<MsalAuthenticationResult> = ({ error }) => {
  console.log(error);
  return <ErrorPage />;
};

const Loading = () => {
  return <LoadingScreen />;
};

type MsalAuthenticationProps = Pick<
  EnvVariables,
  | 'azb2cApiDomain'
  | 'azb2cAuthorityDomain'
  | 'azb2cClientId'
  | 'azb2cEnabled'
  | 'azb2cLoginRedirectPath'
>;
const MsalAuthentication = ({
  children,
  ...envVariables
}: PropsWithChildren<MsalAuthenticationProps>): JSX.Element => {
  const msalRef = useRef<PublicClientApplication>();

  if (!msalRef.current) {
    const msalInstance = new PublicClientApplication(msalConfig(envVariables));
    msalRef.current = msalInstance;

    msalInstance.initialize().then(() => {
      // account selection logic is app dependent. adjust as needed for different use cases
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
      }

      msalInstance.addEventCallback((e) => {
        if (e.eventType === EventType.LOGIN_SUCCESS && e.payload) {
          const account = (e.payload as AuthenticationResult).account;
          msalInstance.setActiveAccount(account);
        }
      });
    });
  }

  if (envVariables.azb2cEnabled) {
    return (
      <MsalProvider instance={msalRef.current}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          errorComponent={ProtectedError}
          loadingComponent={Loading}
        >
          {children}
        </MsalAuthenticationTemplate>
      </MsalProvider>
    );
  } else {
    return <>{children}</>;
  }
};

export default MsalAuthentication;
