import { FormEvent, useRef, useState } from 'react';

import { useDataFetch } from 'src/hooks/useDataFetch';
import { useOnMount } from 'src/hooks/useOnMount';

import { AllCourseMaterialSearchResults } from 'src/types/components';

export const useACMSearch = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const [searchKey, setSearchKey] = useState<string | null>(null);
  const { data, isLoading, error } = useDataFetch<AllCourseMaterialSearchResults>(searchKey);
  const [hasSearchFilters, setHasSearchFilters] = useState(false);

  const onSubmit = async (event?: FormEvent) => {
    event?.preventDefault();

    if (formRef.current) {
      const formData = new FormData(formRef.current);
      const params = new URLSearchParams(formData as unknown as Record<string, string>);

      // Remove query params that are empty strings
      [...params.entries()]
        .filter(([_key, value]) => value === '')
        .forEach(([key]) => params.delete(key));

      const querystring = params.toString();
      setHasSearchFilters(querystring.length > 0);
      setSearchKey(`/api/course-material?${querystring}`);
    }
  };

  useOnMount(onSubmit);

  const clearFilters = () => {
    formRef.current?.reset();
    onSubmit();
  };

  return {
    formRef,
    onSubmit,
    data,
    isLoading,
    error,
    searchKey: searchKey || '',
    hasSearchFilters,
    clearFilters,
  };
};
