import { useEffect, useRef } from 'react';

type OnResizeFn = () => void;
export const useOnResize = (fn?: OnResizeFn) => {
  const onResizeFn = useRef<OnResizeFn | undefined>(fn);

  useEffect(() => {
    const handler = () => requestAnimationFrame(() => onResizeFn.current && onResizeFn.current());
    onResizeFn.current && onResizeFn.current();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  return {
    setOnResizeFn: (fn: OnResizeFn) => (onResizeFn.current = fn),
    clearOnResizeFn: () => (onResizeFn.current = undefined),
  };
};
