import { useCallback } from 'react';
import { GlobalLabelsKey } from 'src/data/fragments/GlobalSettings/formatGlobalSettings';

import { useRouteContext } from './useRouteContext';

export function useGlobalSettings() {
  const context = useRouteContext();

  if (context === undefined) {
    throw new Error('useGlobalSettings must be used within a RouteProvider');
  }

  const getSettingsLabel = useCallback(
    (key: GlobalLabelsKey) => {
      return (
        (context?.routeData?.globalSettings?.labels &&
          context?.routeData?.globalSettings.labels[key]) ||
        ''
      );
    },
    [context]
  );

  return { getSettingsLabel };
}
