import { Popover, Portal } from '@ark-ui/react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { gtmDataLayerPush } from 'src/hooks/useGoogleTagManager';
import { useMsalCustom } from 'src/hooks/useMsalCustom';

import Link from 'components/BaseHelpers/Link';
import Text from 'components/BaseHelpers/Text';

import CaretDown from '../../../assets/icons/caret-down.svg';
import Logo from '../../../assets/icons/logo.svg';
import Search from '../../../assets/icons/search.svg';
import { useOnResize } from '../../../hooks/useOnResize';
import { ComponentProps } from '../../../types/component-props';
import { CoreGlobalNavigationHeaderData } from '../../../types/components';
import MegaMenu from './components/MegaMenu/MegaMenu';
import MobileMenu from './components/MobileMenu';
import UserAccount from './components/UserAccount';

import styles from './CoreGlobalNavigationHeader.module.scss';

export type CoreGlobalNavigationHeaderProps = ComponentProps & CoreGlobalNavigationHeaderData;

const CoreGlobalNavigationHeader = (props: CoreGlobalNavigationHeaderProps): JSX.Element => {
  const { logoLink, courseName, searchLink, primaryNavigation, signOutLabel } = props.fields || {};

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const [megaMenuSelected, setMegaMenuSelected] = useState<number | null>(null);
  const navbarRef = useRef<HTMLDivElement>(null);
  const { setOnResizeFn } = useOnResize();
  const router = useRouter();
  const { accountInfo } = useMsalCustom();

  useEffect(() => {
    setMobileMenuOpen(false);
    setMegaMenuSelected(null);
  }, [router.asPath]);

  setOnResizeFn(() => {
    setMobileMenuOpen(false);
    setMegaMenuSelected(null);
    const navbarHeight = navbarRef.current?.offsetHeight;
    document.documentElement.style.setProperty('--NavigationHeader__height', `${navbarHeight}px`);
  });

  if (!courseName) {
    return <></>;
  }

  return (
    <header
      className={cn(
        styles.main,
        mobileMenuOpen || Number.isInteger(megaMenuSelected)
          ? styles['menu-open']
          : styles['menu-closed']
      )}
    >
      <div className={styles.navbar} ref={navbarRef} id="CoreGlobalNavigationHeader">
        <a href="#main" className={styles['skip-link']}>
          {'Skip to main content'}
        </a>
        <Link
          {...logoLink}
          aria-label={logoLink.label}
          className={styles.logo}
          data-gtm-selector="header-link"
        >
          <Logo aria-hidden="true" className={styles['logo-icon']} />
        </Link>
        <div className={styles['mega-menu-links']}>
          <Link href={'/'} className={styles.course} data-gtm-selector="header-link">
            <Text field={courseName} />
          </Link>

          <div className={styles['tabs-root']}>
            <ul className={styles['primary-links']}>
              {primaryNavigation.map((menu, index) => {
                const { label } = menu;

                if (menu.format === 'internal_external_links' || menu.format === 'internal_links') {
                  const hasLinks = menu.columns
                    .flatMap((column) => column.links)
                    .some((link) => Boolean(link.href) && Boolean(link.label));
                  if (!hasLinks) {
                    return <></>;
                  }
                }

                return (
                  <li key={index}>
                    {menu.format === 'internal_page' ? (
                      <Link
                        {...menu.link}
                        className={styles['primary-link']}
                        data-gtm-selector="header-link"
                        onClick={() => {
                          try {
                            gtmDataLayerPush({
                              event: 'NavLabelClick',
                              Click: `Top Nav - ${menu.label}`,
                              pageUrl: location.pathname,
                              pageTitle: document.title,
                              userId: accountInfo?.localAccountId,
                            });
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      >
                        <Text
                          className={styles['primary-link-label']}
                          field={menu.link.label}
                        ></Text>
                      </Link>
                    ) : (
                      <Popover.Root
                        modal
                        closeOnInteractOutside={true}
                        open={megaMenuSelected === index}
                        onOpenChange={() => {
                          megaMenuSelected === index
                            ? setMegaMenuSelected(null)
                            : setMegaMenuSelected(index);

                          try {
                            gtmDataLayerPush({
                              event: 'NavLabelClick',
                              Click: `Top Nav - ${menu.label}`,
                              pageUrl: location.pathname,
                              pageTitle: document.title,
                              userId: accountInfo?.localAccountId,
                            });
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                      >
                        <Popover.Trigger
                          className={styles['primary-link']}
                          data-gtm-selector="header-link"
                        >
                          <span className={styles['primary-link-label']}>{label}</span>
                          {<CaretDown className={styles.caret} />}
                        </Popover.Trigger>
                        <Portal>
                          <MegaMenu key={index} primaryNavigationItem={menu} />
                        </Portal>
                      </Popover.Root>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles.icons}>
            <Link className={styles.search} {...searchLink} data-gtm-selector="header-link">
              <Search aria-hidden="true" />
            </Link>

            <MobileMenu
              primaryNavigation={primaryNavigation}
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
              signOutLabel={signOutLabel}
            />
            <UserAccount signOutLabel={signOutLabel} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default CoreGlobalNavigationHeader;
