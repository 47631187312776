import { Checkbox } from '@ark-ui/react';
import { useState } from 'react';

import Check from 'assets/icons/check.svg';

import Switch from 'components/BaseHelpers/Switch';

import styles from './CheckboxGroup.module.scss';

export type CheckboxGroupProps = {
  label: string;
  name: string;
  options: Array<{
    value: string;
    sessionNumber: string;
    title: string;
  }>;
  toggleLabel: string;
  setIsInvalid: (isInvalid: boolean) => void;
};

const CheckboxGroup = ({
  name,
  label,
  options,
  toggleLabel,
  setIsInvalid,
}: CheckboxGroupProps): JSX.Element => {
  const allValuesSelected = options.map(() => true);
  const allValuesDeselected = options.map(() => false);
  const [checkboxValues, setCheckboxValues] = useState(allValuesSelected);

  const isAllSelected = checkboxValues.every(Boolean);

  const updateValueAtIndex = (index: number, value: boolean) => {
    setCheckboxValues((currentValue) => {
      const newValue = [...currentValue];
      newValue[index] = value;
      setIsInvalid(newValue.every((value) => value === false));
      return newValue;
    });
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <div id="session-group-label" className={styles['filter-label']}>
          {label}
        </div>
        <Switch
          label={toggleLabel}
          className={styles.toggle}
          checked={isAllSelected}
          onCheckedChange={() => {
            setCheckboxValues(isAllSelected ? allValuesDeselected : allValuesSelected);
            setIsInvalid(isAllSelected);
          }}
        />
      </div>
      <div className={styles.group} role="group" aria-labelledby="session-group-label">
        {options.map((option, index) => (
          <Checkbox.Root
            className={styles.checkbox}
            // when isAllSelected is true, we set the name to be an empty string
            // so that it isn't included in the form data. The reason is because,
            // if all options are selected, then we are essentially returning records
            // with any value for "sessions", which is essentially the same as not applying
            // a "sessions" filter
            name={isAllSelected ? '' : name}
            value={option.value}
            key={option.value}
            checked={checkboxValues[index]}
            onCheckedChange={(event) => updateValueAtIndex(index, Boolean(event.checked))}
          >
            <Checkbox.HiddenInput />
            <Checkbox.Label className={styles.label}>
              <div className={styles.box}>
                <Check className={styles.check} />
              </div>
              <span>{option.sessionNumber}</span>
              <span> {option.title}</span>
            </Checkbox.Label>
          </Checkbox.Root>
        ))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
