import cn from 'classnames';
import { useEffect, useRef } from 'react';

import { hasItems } from 'src/utils/hasItems';

import Link from 'components/BaseHelpers/Link';
import Text from 'components/BaseHelpers/Text';
import TextCta from 'components/BaseHelpers/TextCta';

import Logo from '../../../assets/icons/logo.svg';
import { ComponentProps } from '../../../types/component-props';
import { CoreGlobalNavigationFooterData } from '../../../types/components';

import styles from './CoreGlobalNavigationFooter.module.scss';

export type CoreGlobalNavigationFooterProps = ComponentProps & CoreGlobalNavigationFooterData;

const CoreGlobalNavigationFooter = (props: CoreGlobalNavigationFooterProps): JSX.Element => {
  const { columns, logoLink, copyright, disclaimer } = props.fields || {};
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Select all interactive elements
    const interactiveElements = footerRef?.current?.querySelectorAll(
      'button, a, input, select, textarea, [role="button"], [role="link"], [role="checkbox"], [role="radio"], [role="switch"], [tabindex]'
    );

    // Define the focus event handler
    const handleFocus = () => {
      window.scrollTo({
        top: document?.body?.scrollHeight,
        behavior: 'smooth',
      });
    };

    // Add focus event listener to each interactive element
    interactiveElements?.forEach((element) => {
      element.addEventListener('focus', handleFocus);
    });

    // Cleanup function to remove event listeners
    return () => {
      interactiveElements?.forEach((element) => {
        element.removeEventListener('focus', handleFocus);
      });
    };
  }, []);

  if (!logoLink) {
    return <></>;
  }

  return (
    <footer ref={footerRef} className={styles.main}>
      {hasItems(columns) && (
        <div className={cn(styles.columns, 'spacer', 'borders')}>
          {columns?.map((column, columnIndex) => {
            const links = column.links;
            return (
              <div key={columnIndex} className={styles.column}>
                {hasItems(links) && (
                  <ul className={styles.links}>
                    {links.map((link, keyIndex) => {
                      return (
                        <li key={keyIndex}>
                          <TextCta tag="a" {...link} data-gtm-selector="footer-link" />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      )}
      <div className={cn(styles.footer, 'container-8', 'spacer')}>
        <Link
          aria-label={logoLink.label}
          {...logoLink}
          className={styles.icon}
          data-gtm-selector="footer-link"
        >
          <Logo className={styles.logo} />
        </Link>
        {(copyright || disclaimer) && (
          <div className={styles.text}>
            <Text field={copyright} />
            <Text field={disclaimer} />
          </div>
        )}
      </div>
    </footer>
  );
};

export default CoreGlobalNavigationFooter;
