import cn from 'classnames';
import { FormEvent, useEffect, useRef, useState } from 'react';

import { useGlobalSettings } from 'src/hooks/useGlobalSettings';
import { gtmDataLayerPush } from 'src/hooks/useGoogleTagManager';
import { useMsalCustom } from 'src/hooks/useMsalCustom';

import { hasItems } from 'src/utils/hasItems';

import BoxCta from 'components/BaseHelpers/BoxCta';
import RichText from 'components/BaseHelpers/RichText';
import SelectDropdown from 'components/BaseHelpers/SelectDropdown';
import Text from 'components/BaseHelpers/Text';
import TextCta from 'components/BaseHelpers/TextCta';

import { ComponentProps } from '../../../types/component-props';
import { CoreSubmitQuestionFormData } from '../../../types/components';
import SubmitFormSubmissionMessage from './components/SubmitFormSubmissionMessage';

import styles from './CoreSubmitQuestionForm.module.scss';

type FormState = 'default' | 'success' | 'error';

export type CoreSubmitQuestionFormProps = ComponentProps & CoreSubmitQuestionFormData;

const CoreSubmitQuestionForm = (props: CoreSubmitQuestionFormProps): JSX.Element => {
  const {
    header,
    description,
    textInputLabel,
    textInputPlaceholder,
    optionalFieldsDescription,
    optionalFieldsLabel,
    sessionDropdownLabel,
    sessionFilter,
    trackDropdownLabel,
    trackFilter,
    facultyDropdownLabel,
    facultyFilter,
    cancelButtonLabel,
    submitButtonLabel,
    successMessage,
    errorMessage,
    formId,
  } = props.fields;

  const formRef = useRef<HTMLFormElement>(null);
  const [formState, setFormState] = useState<FormState>('default');
  const [questionValue, setQuestionValue] = useState<string>('');
  const [clearForm, setClearForm] = useState<boolean>(false);
  const maxCharacters = 1000;

  const { getSettingsLabel } = useGlobalSettings();
  const { msalFetch, accountInfo } = useMsalCustom();

  const onSubmit = async (event?: FormEvent) => {
    event?.preventDefault();

    if (formRef.current) {
      const formData = new FormData(formRef.current);

      const userData = {
        pageUrl: `${location.protocol}//${location.host}${location.pathname}`,
        formId: formId,
        question: formData.get('question'),
        filters: {
          session: formData?.get('session') || '',
          track: formData?.get('track') || '',
          faculty: formData?.get('faculty') || '',
        },
      };

      const userDataWithAccount = {
        ...userData,
        userName: accountInfo?.name || '',
        userEmail: accountInfo?.username || '',
      };

      try {
        const response = await msalFetch(
          '/api/submit-question',
          'POST',
          JSON.stringify(userDataWithAccount)
        );
        gtmDataLayerPush({
          event: 'FormSubmission',
          formData: userData,
          userId: accountInfo?.localAccountId,
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        setFormState('success');
      } catch (error) {
        console.error('Error:', error);
        setFormState('error');
      }
    }
  };

  useEffect(() => {
    if (clearForm) {
      setQuestionValue('');
      setClearForm(false);
    }
  }, [clearForm]);

  const charactersRemaining = maxCharacters - (questionValue?.length || 0);

  return (
    <div className={cn(styles.main, 'container-8', 'dark')}>
      {(header || description) && (
        <div className={cn(styles.intro, 'spacer')}>
          <Text className={styles.header} tag="h2" field={header} />
          <RichText field={description} />
        </div>
      )}

      <form ref={formRef} onSubmit={onSubmit} className={cn(styles.form, 'spacer')}>
        <div className={styles['text-input']}>
          <div className={styles['help-text']}>
            <span className={styles.asterik}>*</span>
            <Text tag="span" field={'Required fields.'} />
          </div>
          <div>
            <Text tag="span" field={textInputLabel} />
            <span className={styles.asterik}>*</span>
          </div>

          <textarea
            value={questionValue}
            required
            rows={5}
            name={'question'}
            className={styles['text-area']}
            maxLength={maxCharacters}
            placeholder={textInputPlaceholder}
            onChange={(event) => {
              setQuestionValue(event.target.value);
            }}
          />
          <Text
            className={cn(styles.character, {
              [styles['character-error']]: charactersRemaining === 0,
            })}
            field={`${getSettingsLabel('charactersRemainingLabel').replace('{COUNT}', `${charactersRemaining}`)}`}
          />
        </div>
        {(hasItems(sessionFilter) || hasItems(facultyFilter) || hasItems(trackFilter)) && (
          <div className={styles['optional-fields']}>
            {(optionalFieldsLabel || optionalFieldsDescription) && (
              <div>
                <Text
                  tag="p"
                  className={styles['optional-fields-label']}
                  field={optionalFieldsLabel}
                />
                <Text
                  tag="p"
                  className={styles['optional-fields-description']}
                  field={optionalFieldsDescription}
                />
              </div>
            )}

            <div className={styles.filters}>
              {hasItems(sessionFilter) && (
                <SelectDropdown
                  className={styles.dropdown}
                  label={sessionDropdownLabel}
                  name={'session'}
                  options={[
                    { value: '', label: getSettingsLabel('selectASessionPlaceholder') },
                    ...sessionFilter,
                  ]}
                  clear={clearForm}
                />
              )}
              {hasItems(trackFilter) && (
                <SelectDropdown
                  className={styles.dropdown}
                  label={trackDropdownLabel}
                  name={'track'}
                  options={[
                    { value: '', label: getSettingsLabel('selectATrackPlaceholder') },
                    ...trackFilter,
                  ]}
                  clear={clearForm}
                />
              )}
              {hasItems(facultyFilter) && (
                <SelectDropdown
                  className={styles.dropdown}
                  label={facultyDropdownLabel}
                  name={'faculty'}
                  options={[
                    { value: '', label: getSettingsLabel('selectFacultyPlaceholder') },
                    ...facultyFilter,
                  ]}
                  clear={clearForm}
                />
              )}
            </div>
          </div>
        )}
        <div className={styles.submit}>
          <TextCta
            tag="button"
            noIcon={true}
            onClick={(e) => {
              e.preventDefault();
              setClearForm(true);
            }}
            label={cancelButtonLabel}
          />

          <BoxCta
            type="submit"
            disabled={!questionValue}
            onClick={() => {}} // Making button type submit in a form will force a click event
            tag="button"
            label={submitButtonLabel}
          />
        </div>
      </form>

      <div
        className={cn(styles.form, styles['submit-message'], 'spacer', {
          [styles['hidden']]: formState !== 'success',
        })}
      >
        <SubmitFormSubmissionMessage
          messageType="success"
          {...successMessage}
          clickHandler={() => {
            setClearForm(true);
            setFormState('default');
          }}
        />
      </div>

      <div
        className={cn(styles.form, styles['submit-message'], 'spacer', {
          [styles['hidden']]: formState !== 'error',
        })}
      >
        <SubmitFormSubmissionMessage
          messageType="error"
          {...errorMessage}
          clickHandler={() => {
            setFormState('default');
            try {
              gtmDataLayerPush({
                event: 'FormErrorBackToForm',
                pageUrl: location.pathname,
                pageTitle: document.title,
                formId: formId,
                userId: accountInfo?.localAccountId,
              });
            } catch (error) {
              console.log(error);
            }
          }}
        />
      </div>
    </div>
  );
};

export default CoreSubmitQuestionForm;
