import { LevelLabel, Transport, TransportContext } from '../types';

export type ConsoleTransportOptions = {
  level?: LevelLabel;
  logFormattedData?: boolean;
};

export class Console implements Transport {
  level?: LevelLabel;
  logFormattedData: boolean;

  constructor(options?: ConsoleTransportOptions) {
    this.level = options?.level;
    this.logFormattedData = options?.logFormattedData ?? false;
  }

  log({ level, formattedData, rawData }: TransportContext): void {
    const method = level.label;

    if (this.logFormattedData) {
      console[method](formattedData);
    } else {
      console[method](rawData);
    }
  }
}

export default Console;
