import { LevelLabel, Transport, TransportContext } from '../types';
import { isBrowser } from '../utils';

export type BrowserTransportOptions = {
  level?: LevelLabel;
  endpoint: string;
};

export class Browser implements Transport {
  level?: LevelLabel;
  endpoint: string;

  constructor(options: BrowserTransportOptions) {
    this.level = options?.level;
    this.endpoint = options?.endpoint;

    if (!this.endpoint) {
      console.warn('LOGGER_API_ENDPOINT env variable needed for Browser transport');
    }
  }

  log({ formattedData }: TransportContext): void {
    if (!isBrowser()) {
      return;
    }

    if (!this.endpoint) {
      console.error('LOGGER_API_ENDPOINT env variable needed for Browser transport');
      return;
    }

    const metaData = {
      environment: 'browser',
      url: window.location.href,
    };

    const data = Object.assign({}, formattedData, metaData);

    navigator.sendBeacon(
      this.endpoint,
      new Blob([JSON.stringify(data)], {
        type: 'application/json',
      })
    );
  }
}

export default Browser;
