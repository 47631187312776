import { useMsal } from '@azure/msal-react';

import { useAuthConfig } from '../authConfig';

export const useMsalCustom = () => {
  const { instance } = useMsal();
  const { apiConfig, msalConfig } = useAuthConfig();

  const msalFetch = async (endpoint: string, method: 'GET' | 'POST' = 'GET', body?: string) => {
    const account = instance.getActiveAccount();
    const options: RequestInit = {
      method,
      body,
    };

    if (account) {
      const result = await instance.acquireTokenSilent({
        scopes: [
          `${apiConfig.apiDomain}/${msalConfig.auth.clientId}/${apiConfig.protectedRoutes.apiRead.scopeName}`,
        ],
        account: account,
      });
      const headers = new Headers();
      const bearer = result.accessToken;
      headers.append('Authorization', bearer);
      options.headers = headers;
    }
    return fetch(endpoint, options);
  };

  const logout = () => {
    const logoutRequest = {
      account: instance.getActiveAccount(),
    };

    instance.logoutRedirect(logoutRequest);
  };

  return {
    msalFetch,
    logout,
    accountInfo: instance?.getActiveAccount(),
  };
};
