import cn from 'classnames';

import { useMsalCustom } from 'src/hooks/useMsalCustom';

import Text from 'components/BaseHelpers/Text';
import VideoEmbed from 'components/BaseHelpers/VideoEmbed';

import { ComponentProps } from '../../../types/component-props';
import { CoreHeroVideoData } from '../../../types/components';

import styles from './CoreHeroVideo.module.scss';

export type Props = ComponentProps & CoreHeroVideoData;

// TODO add participant name

const CoreHeroVideo = (props: Props): JSX.Element => {
  const { greeting, header, embedCode } = props.fields || {};
  const { accountInfo } = useMsalCustom();
  if (!header || !embedCode) {
    return <></>;
  }
  const assembledGreeting = `${greeting || ''} ${accountInfo?.name || ''}`;

  return (
    <div className={cn(styles.main, 'container-8', 'spacer')}>
      <div className={styles.text}>
        <Text tag="p" className={styles.greeting} field={assembledGreeting} />
        <Text tag="h1" className={styles.header} field={header} />
      </div>
      <VideoEmbed embedCode={embedCode} />
    </div>
  );
};

export default CoreHeroVideo;
