import { Dialog, Portal } from '@ark-ui/react';
import { PropsWithChildren, useState } from 'react';

import { useOnMount } from 'src/hooks/useOnMount';
import { useOnResize } from 'src/hooks/useOnResize';

import CloseIcon from 'assets/icons/close.svg';
import FilterIcon from 'assets/icons/filter.svg';

import styles from './ModalWhenMobile.module.scss';

export type ModalWhenMobileProps = PropsWithChildren;

const ModalWhenMobile = ({ children }: ModalWhenMobileProps): JSX.Element => {
  const [isMobile, setIsMobile] = useState(false);
  const checkIfMobile = () => {
    const isMobileWidth = window.innerWidth < 1200;
    if (isMobileWidth !== isMobile) {
      setIsMobile(isMobileWidth);
    }
  };
  useOnMount(checkIfMobile);
  const { setOnResizeFn } = useOnResize();
  setOnResizeFn(checkIfMobile);

  if (isMobile) {
    return (
      <Dialog.Root>
        <Portal>
          <Dialog.Trigger className={styles.trigger} aria-label="Show Search Filters">
            <FilterIcon className={styles.icon} />
          </Dialog.Trigger>
        </Portal>
        <Portal>
          <Dialog.Backdrop className={styles.backdrop} />
          <Dialog.Positioner className={styles.positioner}>
            <Dialog.Context>
              {(api) => {
                return (
                  <Dialog.Content
                    onClick={(event) => {
                      // Check if submit button was clicked and if so, close the modal.
                      if (
                        event.target instanceof HTMLButtonElement &&
                        event.target.type === 'submit'
                      ) {
                        api.setOpen(false);
                      } else if ((event.target as HTMLElement).closest('button[type="submit"]')) {
                        api.setOpen(false);
                      }
                    }}
                    className={styles.content}
                  >
                    <Dialog.CloseTrigger
                      className={styles['close-trigger']}
                      aria-label="Close filters"
                    >
                      <CloseIcon className={styles['close-icon']} />
                    </Dialog.CloseTrigger>
                    {children}
                  </Dialog.Content>
                );
              }}
            </Dialog.Context>
          </Dialog.Positioner>
        </Portal>
      </Dialog.Root>
    );
  } else {
    return <>{children}</>;
  }
};

export default ModalWhenMobile;
