import cn from 'classnames';
import { useRef, useState } from 'react';

import Close from 'assets/icons/close.svg';
import Search from 'assets/icons/search.svg';

import styles from './TextInput.module.scss';

export type TextInputProps = {
  label: string;
  placeholder: string;
  name: string;
};

const TextInput = ({ label, placeholder, name }: TextInputProps): JSX.Element => {
  const [hasInputValue, setHasInputValue] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={styles.main}>
      <label htmlFor="keyword-input" className={styles['filter-label']}>
        {label}
      </label>
      <div className={styles.wrapper}>
        <div className={styles.field}>
          <Search className={styles['search-icon']} />
          <input
            className={styles.input}
            ref={inputRef}
            type="text"
            name={name}
            placeholder={placeholder}
            onChange={(event) => {
              setHasInputValue(Boolean(event.target.value));
            }}
            id="keyword-input"
          />
        </div>
        <button
          type="button"
          onClick={() => {
            if (inputRef?.current) {
              inputRef.current.value = '';
              setHasInputValue(false);
              inputRef.current.focus();
            }
          }}
          className={cn(styles['close-button'], {
            [styles['close-button--visible']]: hasInputValue,
          })}
        >
          <Close className={styles['close-icon']} />
        </button>
      </div>
    </div>
  );
};

export default TextInput;
