import { NextPage } from 'next';
import Layout from 'src/Layout';

import { useDataFetch } from 'src/hooks/useDataFetch';

import { logger } from 'lib/logger';
import { parseRequestMetaData } from 'lib/logger/package';

import LoadingScreen from 'components/BaseHelpers/LoadingScreen';

import { RouteHandlerReturn } from '../types/model';

/**
 * Rendered for 500 errors on both server and client. Used only in Production mode.
 * @link https://nextjs.org/docs/advanced-features/custom-error-page#more-advanced-error-page-customizing
 */

const ErrorPage: NextPage = () => {
  const { data, isLoading } = useDataFetch<RouteHandlerReturn>(`/api/pages/error`);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return <Layout routeData={data ? data : fallbackData()} />;
};

ErrorPage.getInitialProps = ({ req, err }) => {
  const childLogger = logger.child(parseRequestMetaData(req));
  childLogger.error(err);
  return { error: err };
};

export default ErrorPage;

const fallbackData = (): RouteHandlerReturn => {
  return {
    props: {
      globalSettings: {
        labels: {
          expandAllLabel: '',
          collapseAllLabel: '',
          linkedinLabel: '',
          bioLabel: '',
          videosSectionLabel: '',
          materialsSectionLabel: '',
          selectASessionPlaceholder: '',
          selectATrackPlaceholder: '',
          selectFacultyPlaceholder: '',
          charactersRemainingLabel: '',
          resourceLabel: '',
          sessionLabel: '',
        },
        miscSettings: {
          pageTitleSiteName: '',
        },
      },
      route: {
        itemId: -1,
        type: 'page',
        template: 'flexible-template',
        path: '/error',
        slug: 'error',
        title: 'Error',
        fields: {},
        placeholders: {
          content: [
            {
              componentName: 'CoreGlobalNavigationHeader',
              fields: {
                logoLink: {
                  label: 'Cooley',
                  href: 'https://www.cooley.com/',
                  target: '_blank',
                },
                courseName: 'IPO Institute',
                signOutLabel: 'Sign Out',
                searchLink: {
                  label: 'Course Materials',
                  href: '/course-materials/',
                },
                primaryNavigation: [],
              },
            },
            {
              componentName: 'CoreFlexibleHero',
              fields: {
                format: 'no_image',
                theme: 'dark',
                eyebrow: '',
                headline: 'An error occured',
                description: '<p>Please try again later</p>',
                subHeadline: '',
                cta: {
                  label: '',
                  href: '',
                },
                isH1: true,
              },
            },
            {
              componentName: 'CoreGlobalNavigationFooter',
              fields: {
                copyright: '©{YEAR} Cooley LLP and Cooley (UK) LLP. All rights reserved.'.replace(
                  '{YEAR}',
                  new Date().getUTCFullYear().toString()
                ),
                logoLink: {
                  label: '',
                  href: '/',
                },
                disclaimer:
                  '<p>Cooley® is a registered service mark of Cooley LLP in the United States and European Union as well as certain other jurisdictions.</p>\n',
                columns: [],
              },
            },
          ],
        },
      },
      pageTitle: 'Error | Cooley IPO Institute',
    },
    notFound: false,
  };
};
