import { BaseCtaProps } from './BaseCta';

export const useBaseCta = () => {
  const shouldRenderCta = (props: BaseCtaProps): boolean => {
    if (props.tag === 'button') {
      return Boolean(props.label) || Boolean(props.children);
    }

    if (props.tag === 'a') {
      const hasHref = Boolean(props.href);
      const hasContent = Boolean(props.children);
      return hasHref && hasContent;
    }

    if (props.tag === 'span') {
      return true;
    }

    const never: never = props;
    return never;
  };

  return {
    shouldRenderCta,
  };
};
