import {
  Level,
  LevelLabel,
  traceLevel,
  debugLevel,
  infoLevel,
  warnLevel,
  errorLevel,
  Transport,
  Formatter,
  Data,
} from './types';
import { getLevelFromLabel } from './utils';

export type LoggerOptions = {
  level?: LevelLabel;
  silent?: boolean;
  transports?: Transport[];
  formatter?: Formatter;
  metaData?: Record<string, unknown>;
};

export class Logger {
  defaultLevel: Level;
  silent: boolean;
  transports: Transport[];
  formatter?: Formatter;
  metaData: Record<string, unknown>;

  constructor(options: LoggerOptions) {
    this.defaultLevel = getLevelFromLabel(options.level, infoLevel);

    this.transports = options.transports ?? [];
    this.silent = options.silent ?? false;
    this.formatter = options.formatter;
    this.metaData = options.metaData ?? {};
  }

  log(level: Level, data: Data): void {
    if (this.silent) {
      return;
    }

    try {
      const formattedData =
        typeof this.formatter?.format === 'function'
          ? (this.formatter.format(
              structuredClone({ data, level, metaData: this.metaData })
            ) as Record<string, unknown>)
          : { message: 'Please provide a Formatter' };

      this.transports.forEach((transport) => {
        const transportLevel = getLevelFromLabel(transport.level, this.defaultLevel);

        if (level.value >= transportLevel.value) {
          transport.log(
            structuredClone({
              formattedData,
              rawData: data,
              level,
            })
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  trace(data: Data): void {
    this.log(traceLevel, data);
  }

  debug(data: Data): void {
    this.log(debugLevel, data);
  }

  info(data: Data): void {
    this.log(infoLevel, data);
  }

  warn(data: Data): void {
    this.log(warnLevel, data);
  }

  error(data: Data): void {
    this.log(errorLevel, data);
  }

  child(metaData: Record<string, unknown> = {}): Logger {
    return new Logger({
      level: this.defaultLevel.label,
      formatter: this.formatter,
      transports: this.transports,
      silent: this.silent,
      metaData: {
        ...structuredClone(this.metaData),
        ...structuredClone(metaData),
      },
    });
  }
}
