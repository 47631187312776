import { useCallback } from 'react';
import { componentFactory } from 'temp/componentFactory';

import { ComponentData } from 'src/types/components';

export type PlaceholdersProps = {
  placeholders?: ComponentData[];
};

type ComponentNotFoundProps = {
  componentName: string;
};

const ComponentNotFound = (props: ComponentNotFoundProps) => {
  const { componentName } = props || {};
  return <div>Component {componentName} not yet implemented</div>;
};

const Placeholders = (props: PlaceholdersProps): JSX.Element[] | JSX.Element => {
  const { placeholders } = props;

  const getComponentForRendering = useCallback((placeholder: ComponentData) => {
    if (!componentFactory || typeof componentFactory !== 'function') {
      console.warn(
        `No componentFactory was available to service request for component ${placeholder.componentName}`
      );
      return null;
    }
    const component = componentFactory(placeholder.componentName);
    return component;
  }, []);

  if (!placeholders?.length) {
    return <></>;
  }

  return placeholders.map((placeholder, index) => {
    const Component = getComponentForRendering(placeholder);
    if (!Component) {
      return (
        <ComponentNotFound key={`component-${index}`} componentName={placeholder.componentName} />
      );
    }
    return <Component key={`component-${index}`} {...placeholder} />;
  });
};

export default Placeholders;
