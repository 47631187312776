import cn from 'classnames';

import BoxCta from 'components/BaseHelpers/BoxCta';
import Image from 'components/BaseHelpers/Image';
import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { ComponentProps } from '../../../types/component-props';
import { CoreFlexibleHeroData } from '../../../types/components';

import styles from './CoreFlexibleHero.module.scss';

export type CoreFlexibleHeroProps = ComponentProps & CoreFlexibleHeroData;

const CoreFlexibleHero = (props: CoreFlexibleHeroProps): JSX.Element => {
  const { eyebrow, headline, subHeadline, description, image, cta, theme, format, isH1 } =
    props.fields;

  if (!headline) {
    return <></>;
  }
  const tag = isH1 ? 'h1' : 'h2';
  return (
    <div className={cn(styles.main, styles[format], theme, 'spacer')}>
      <div className={styles.content}>
        <Text className={styles.eyebrow} field={eyebrow} />
        <Text tag={tag} className={styles.headline} field={headline} />
        <Text className={styles['sub-headline']} field={subHeadline} />
        <RichText className={styles.description} field={description} />

        {cta && <BoxCta className={styles.cta} tag="a" {...cta} />}
      </div>

      {format !== 'no_image' && image && (
        <div className={styles.image}>
          <Image {...image} fill={true} />
        </div>
      )}
    </div>
  );
};

export default CoreFlexibleHero;
