import cn from 'classnames';

import RichText from 'components/BaseHelpers/RichText';
import Text from 'components/BaseHelpers/Text';

import { ComponentProps } from '../../../types/component-props';
import { CoreFlexibleGridListData } from '../../../types/components';

import styles from './CoreFlexibleGridList.module.scss';

export type CoreFlexibleGridListProps = ComponentProps & CoreFlexibleGridListData;

const CoreFlexibleGridList = (props: CoreFlexibleGridListProps): JSX.Element => {
  const { moduleHeader, description, items } = props.fields || {};

  if (items.length < 2) {
    return <></>;
  }
  return (
    <div className={cn(styles.main, 'container-8', 'spacer', 'borders')}>
      {(moduleHeader || description) && (
        <div className={styles.lead}>
          <Text tag="h2" className={styles.header} field={moduleHeader} />
          <RichText className={styles.description} field={description} />
        </div>
      )}

      <ul className={styles.items}>
        {items.map((item, index) => {
          const { description } = item || {};

          return (
            <li key={`core-flex-grid-item-${index}`} className={styles.item}>
              <RichText field={description} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CoreFlexibleGridList;
