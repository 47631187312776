import cn from 'classnames';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import BoxCta from 'components/BaseHelpers/BoxCta';
import Text from 'components/BaseHelpers/Text';

import Close from '../../../assets/icons/close.svg';
import { ComponentProps } from '../../../types/component-props';
import { CoreNotificationsBannerData } from '../../../types/components';

import styles from './CoreNotificationsBanner.module.scss';

export type CoreNotificationsBannerProps = ComponentProps & CoreNotificationsBannerData;

const CoreNotificationsBanner = (props: CoreNotificationsBannerProps): JSX.Element => {
  const { label, description, primaryCta, versionNumber } = props.fields || {};

  const [showComponent, setShowComponent] = useState(false);
  const cookieName = 'IPOInstituteBanner';

  useEffect(() => {
    const cookieValue = parseInt(Cookies.get(cookieName) || '', 10);
    if (isNaN(cookieValue) || versionNumber > cookieValue) {
      setShowComponent(true);
    }
  }, [versionNumber]);

  const dismissBanner = () => {
    try {
      Cookies.set(cookieName, `${versionNumber}`);
      setShowComponent(false);
    } catch (error) {
      console.error('Failed to set cookie:', error);
    }
  };

  if (!showComponent) {
    return <></>;
  }

  return (
    <div className={cn(styles.main, 'container-8', 'dark')}>
      <div className={styles.text}>
        <Text className={styles.label} field={label} />
        <Text field={description} />
      </div>
      <div className={styles.buttons}>
        <BoxCta tag="a" {...primaryCta} />
        <button
          className={styles.close}
          onClick={() => dismissBanner()}
          aria-label="Dismiss Banner"
        >
          <Close />
        </button>
      </div>
    </div>
  );
};

export default CoreNotificationsBanner;
