import { Collapsible, Popover, Portal } from '@ark-ui/react';
import cn from 'classnames';
import lottie, { AnimationItem } from 'lottie-web';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { useOnMount } from 'src/hooks/useOnMount';

import Link from 'components/BaseHelpers/Link';
import Text from 'components/BaseHelpers/Text';
import MegaMenuLink from 'components/Core/CoreGlobalNavigationHeader/components/MegaMenuLink';

import CaretDown from '../../../../../assets/icons/caret-down.svg';
import MenuAnimation from '../../../../../assets/lottie/menu-icon.json';
import { CoreGlobalNavigationHeaderData } from '../../../../../types/components';
import { UserAccountProfile } from '../UserAccount/UserAccount';

import styles from './MobileMenu.module.scss';

export type MobileMenuProps = {
  primaryNavigation: CoreGlobalNavigationHeaderData['fields']['primaryNavigation'];
  mobileMenuOpen: boolean;
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
  signOutLabel: string;
};

const MobileMenu = (props: MobileMenuProps): JSX.Element => {
  const { primaryNavigation, mobileMenuOpen, setMobileMenuOpen, signOutLabel } = props || {};
  const lottieContainerRef = useRef<HTMLDivElement>(null);
  const lottieAnimationRef = useRef<AnimationItem>();
  const { isMounted } = useOnMount();

  const [openStates, setOpenStates] = useState(primaryNavigation.map(() => false));

  const toggleIndividual = (index: number) => {
    const newStates = primaryNavigation.map(() => false);
    if (openStates[index]) {
      newStates[index] = false;
    } else {
      newStates[index] = !newStates[index];
    }

    setOpenStates(newStates);
  };

  useEffect(() => {
    if (!lottieAnimationRef.current && lottieContainerRef.current) {
      lottieAnimationRef.current = lottie.loadAnimation({
        container: lottieContainerRef.current,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: MenuAnimation,
      });
      lottieAnimationRef.current.setSpeed(2);
    }
  }, []);

  useEffect(() => {
    if (lottieAnimationRef.current && isMounted) {
      if (mobileMenuOpen) {
        lottieAnimationRef.current.setDirection(1);
        lottieAnimationRef.current.play();
      } else {
        lottieAnimationRef.current.setDirection(-1);
        lottieAnimationRef.current.play();
      }
    }
  }, [mobileMenuOpen]);

  return (
    <div className={styles.main}>
      <Popover.Root
        modal
        open={mobileMenuOpen}
        onOpenChange={(open) => {
          setMobileMenuOpen(open.open);
        }}
      >
        <Popover.Trigger
          className={styles['mobile-menu-toggle']}
          aria-label="Open Mobile Menu"
          onClick={() => {
            setMobileMenuOpen(!mobileMenuOpen);
          }}
          data-gtm-selector="header-link"
        >
          <div className={styles.lottie} ref={lottieContainerRef} />
        </Popover.Trigger>
        <Portal>
          <Popover.Content asChild>
            <div className={cn(styles.content, { [styles.open]: mobileMenuOpen })}>
              <div className={styles['hidden-close']}>
                <Popover.CloseTrigger
                  className={styles['hidden-close-trigger']}
                  aria-label="Close Mobile Menu"
                ></Popover.CloseTrigger>
              </div>
              <div className={styles['wrapper']}>
                <ul className={styles.accordions}>
                  {primaryNavigation.map((menu, index) => {
                    const { label, format } = menu || {};
                    return (
                      label && (
                        <li key={`accordion-${index}`} className={styles.accordion}>
                          {(format === 'internal_links' ||
                            format === 'sessions' ||
                            format === 'internal_external_links') && (
                            <Collapsible.Root open={openStates[index]}>
                              <Collapsible.Trigger
                                onClick={() => toggleIndividual(index)}
                                className={styles.trigger}
                              >
                                <Text tag="p" className={styles['trigger-label']} field={label} />
                                <CaretDown className={styles.caret} />
                              </Collapsible.Trigger>
                              <Collapsible.Content className={styles['accordion-content']}>
                                {menu.format === 'sessions' && (
                                  <div className={styles.column}>
                                    <MegaMenuLink tag="a" {...menu.link} />
                                    {menu?.sessions?.map((session, index) => {
                                      const { sessionNumber, sessionDate, link } = session || {};
                                      return (
                                        <MegaMenuLink
                                          tag="a"
                                          key={index}
                                          {...link}
                                          number={sessionNumber}
                                          subheadline={sessionDate}
                                        />
                                      );
                                    })}
                                  </div>
                                )}
                                {(menu.format === 'internal_links' ||
                                  menu.format === 'internal_external_links') &&
                                  menu?.columns?.map((column, index) => {
                                    const { label, links } = column || {};

                                    return (
                                      <div key={index} className={styles.column}>
                                        <Text className={styles['links-label']} field={label} />
                                        {links.map((link, index) => (
                                          <MegaMenuLink
                                            tag="a"
                                            variant="secondary"
                                            key={index}
                                            {...link}
                                          />
                                        ))}
                                      </div>
                                    );
                                  })}
                              </Collapsible.Content>
                            </Collapsible.Root>
                          )}
                          {menu.format === 'internal_page' && (
                            <Link
                              {...menu.link}
                              className={styles.trigger}
                              data-gtm-selector="header-link"
                            >
                              <Text tag="p" className={styles['trigger-label']} field={label} />
                            </Link>
                          )}
                        </li>
                      )
                    );
                  })}
                </ul>
                <UserAccountProfile signOutLabel={signOutLabel} />
              </div>
            </div>
          </Popover.Content>
        </Portal>
      </Popover.Root>
    </div>
  );
};

export default MobileMenu;
