import Image from '../Image';

import styles from './LoadingScreen.module.scss';

export type LoadingScreenProps = unknown;

const LoadingScreen = (): JSX.Element => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Image priority={true} className={styles.gif} src="/loading.gif" fill={true} alt="" />
      </div>
    </div>
  );
};

export default LoadingScreen;
